"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Interstitials = void 0;
const t = __importStar(require("io-ts"));
/** Defines the placement of interstitials for a Moda modular sofa.
 * An interstitial is a piece of a sofa that can't be sat on and as such is not an actual seat, but
 * needs to be placed between actual seats of the sofa.
 *
 * It is treated differently to other seats because it does not fall under the usual sofa concept of
 * having x pieces in the right/left/middle.
 *
 * We need to be able to place interstitials between any two sofa pieces. At the moment we represent
 * this by assigning every possible interstitial placement a number with 0 being the leftmost and
 * incrementing by 1 as you traverse the sofa to the rightmost end. Please see this diagram for a
 * visual representation of this convention:
 * https://lucid.app/lucidchart/09033d75-42f2-4454-add0-f2a2c913c261/edit?invitationId=inv_44d699e2-e92c-4e37-a117-be777df11b10
 *
 * The placements object is an array of numbers, where a number present means that this
 * interstitial is present in that placement.
 *
 * E.g. placements of [0, 2, 3] mean this interstitial is placed in the first, third and fourth
 * possible placements.
 *
 * Each set of placements is specific to an exact sofa shape, and needs recalculating if the sofa
 * shape ever changes.
 */
exports.Interstitials = t.partial({
    // this should come from Piece.InterstitialKind
    // TODO: find out how to use InterstitialKind directly rather than redefining the string literal
    // kinds here
    'drinks-cooler-armrest': t.type({ placements: t.array(t.number) }),
});
