import { Model } from '@dora/backpack';
import * as H from 'preact/hooks';
import * as TE from 'fp-ts/TaskEither';
import * as Err from '@fixtuur/error-context';
import { HookError } from '../productPageWrapper/hooks';
import { pipe } from 'fp-ts/function';
import * as E from 'fp-ts/Either';
import { serviceUrls } from '../../environment';

export const useProductModels = (id?: string) => {
    const [productModels, setProductModels] = H.useState<Model.Model[]>([]);
    const [refresher, setRefresher] = H.useState(false);

    H.useEffect(() => {
        id &&
            pipe(
                TE.Do,
                TE.bind('getProductModelsUrl', () => {
                    const url = new URL(
                        `${serviceUrls.toastie.pathname}/models`,
                        serviceUrls.toastie,
                    );

                    url.searchParams.append('productId', id);

                    return TE.right(url.toString());
                }),
                TE.bind('response', ({ getProductModelsUrl }) =>
                    TE.tryCatch(
                        () => fetch(getProductModelsUrl),
                        err =>
                            Err.construct(HookError.FetchError, {
                                error: (err as any)?.message,
                                getProductModelsUrl,
                            }),
                    ),
                ),
                TE.chain(o => {
                    const { response } = o;
                    if (!response.ok) {
                        return TE.left(
                            Err.construct(HookError.NoHttpSuccessCodeError, {
                                responseUrl: response.url,
                                responseStatus: response.status.toString(10),
                                responseStatusText: response.statusText,
                            }),
                        );
                    }
                    return TE.right(o);
                }),
                TE.bind('modelsUnknown', ({ response, getProductModelsUrl }) =>
                    TE.tryCatch(
                        () => response.json(),
                        err =>
                            Err.construct(HookError.ResponseJsonError, {
                                error: (err as any)?.message,
                                getProductModelsUrl,
                                responseUrl: response.url,
                                responseStatus: response.status.toString(10),
                                responseStatusText: response.statusText,
                            }),
                    ),
                ),
                TE.bind(
                    'models',
                    ({
                        modelsUnknown,
                    }): TE.TaskEither<Err.ErrorContext<HookError>, Model.Model[]> => {
                        return pipe(
                            modelsUnknown,
                            Model.modelsFromUnknown,
                            // outer either only returns left if item is not array
                            E.mapLeft(Err.swap(HookError.ResponseNotArrayError)),
                            // ignore all items returned that did not decode successfully
                            E.chain(separated => {
                                // errors from models that failed to decode
                                const lefts = separated.left;
                                // models that successfully decoded
                                const rights = separated.right;

                                if (lefts !== null && lefts.length > 0) {
                                    // TODO: handle better?
                                    console.warn('some models did not decode', { lefts });
                                }

                                if (rights === null) {
                                    return E.left(Err.construct(HookError.NoValidItemsError));
                                }

                                return E.right(rights);
                            }),
                            TE.fromEither,
                        );
                    },
                ),
                TE.map(({ models }) => {
                    setProductModels(models);
                }),
                TE.mapLeft(errorContext =>
                    console.error('fetch models from toastie failed', { errorContext }),
                ),
            )();
    }, [id, refresher]);

    const refresh = () => {
        setRefresher(current => !current);
    };

    return { productModels, refresh };
};
