"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component = exports.Model = void 0;
var Preact = __importStar(require("preact"));
var H = __importStar(require("preact/hooks"));
var FloorSizeForm = __importStar(require("./floorSizeForm"));
var DrawerCtaContentInfo = __importStar(require("./O_DrawerCtaContentInfo"));
var DrawerWithCta = __importStar(require("./O_DrawerWithCta"));
var Model = __importStar(require("./floorSizeForm/model"));
exports.Model = Model;
var Component = function (p) {
    var _a = H.useState(null), width = _a[0], setWidth = _a[1];
    var _b = H.useState(null), length = _b[0], setLength = _b[1];
    var _c = H.useState(false), error = _c[0], setError = _c[1];
    return (Preact.h(DrawerWithCta.Component, { title: "Edit floor size", anchor: "left", platform: p.platform, portrait: p.portrait, onClose: p.onClose, sx: p.sx, open: p.open, paddingTop: p.paddingTop, confirmationBarContents: Preact.h(DrawerCtaContentInfo.Component, { buttonText: "Update", disabled: error, onClick: function () {
                var w = width !== null && width !== void 0 ? width : p.floorSizeMeters.width;
                var l = length !== null && length !== void 0 ? length : p.floorSizeMeters.length;
                p.onUpdate({ width: w, length: l });
            }, loading: p.loading }) },
        Preact.h(FloorSizeForm.Component, { widthMeters: p.floorSizeMeters.width, lengthMeters: p.floorSizeMeters.length, onWidthChange: setWidth, onLengthChange: setLength, onErrorChange: setError })));
};
exports.Component = Component;
exports.Component.displayName = 'UpdateFloorSizeDrawer';
