"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildFormErrors = exports.emptyFormErrors = void 0;
var model_1 = require("./model");
exports.emptyFormErrors = { lengthError: undefined, widthError: undefined };
function buildFormErrors(errors) {
    var formErrors = Object.assign({}, exports.emptyFormErrors);
    errors.forEach(function (_a) {
        var value = _a.value, error = _a.error;
        if (value === 'width') {
            formErrors.widthError = (0, model_1.uiMessageFromError)(error);
        }
        else {
            formErrors.lengthError = (0, model_1.uiMessageFromError)(error);
        }
    });
    return formErrors;
}
exports.buildFormErrors = buildFormErrors;
