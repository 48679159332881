import * as E from 'fp-ts/Either';
import * as Err from '@fixtuur/error-context';
import * as Arr from 'fp-ts/Array';
import { pipe } from 'fp-ts/lib/function';
import { articles, CsvRowInput, normalise, productId } from './csvRowInput';

export interface ModelId {
    productId: string;
    articleType: string;
}

export enum ConversionError {
    NoProductId = 'Unable to find a product ID for this row',
    NoArticles = 'Unable to find any articles for this row',
}

const transformInputAndSplitArticles = (input: CsvRowInput) => {
    // TODO: refactor with Do
    // TODO: separate into separate functions
    return pipe(
        input,
        productId,
        E.fromOption(() => Err.construct(ConversionError.NoProductId)),
        E.map(productId => ({ productId })),
        E.chain(value => {
            return pipe(
                input,
                articles,
                E.fromOption(() => Err.construct(ConversionError.NoArticles)),
                E.map(articles => articles.split(',').map(article => article.trim())),
                E.map(articles => ({ articles, ...value })),
            );
        }),
    );
};

export const fromCsvRowInputs = (
    inputs: CsvRowInput[],
): E.Either<Err.ErrorContext<ConversionError>, ModelId[]> => {
    return pipe(
        inputs,
        Arr.map(normalise),
        E.traverseArray(transformInputAndSplitArticles),
        E.map(x => x as { productId: string; articles: string[] }[]), // TODO: handle type conversion better
        E.map(
            Arr.chain(x =>
                x.articles.map(articleType => ({ productId: x.productId, articleType })),
            ),
        ),
    );
};
