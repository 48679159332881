"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.uiMessageFromError = exports.parse = exports.SpaceDimensionsDialogError = void 0;
var E = __importStar(require("fp-ts/Either"));
var function_1 = require("fp-ts/function");
var SpaceDimensionsDialogError;
(function (SpaceDimensionsDialogError) {
    SpaceDimensionsDialogError["NOT_WHOLE_NUMBER"] = "NOT_WHOLE_NUMBER";
    SpaceDimensionsDialogError["UNDER_MINIMUM_DIMENSION"] = "UNDER_MINIMUM_DIMENSION";
    SpaceDimensionsDialogError["OVER_MAXIMUM_DIMENSION"] = "OVER_MAXIMUM_DIMENSION";
})(SpaceDimensionsDialogError = exports.SpaceDimensionsDialogError || (exports.SpaceDimensionsDialogError = {}));
var parseValue = function (value) {
    var numericInputRegex = /^[0-9]+$/;
    var numericValue = parseInt(value);
    if (!value.match(numericInputRegex)) {
        return E.left(SpaceDimensionsDialogError.NOT_WHOLE_NUMBER);
    }
    else if (numericValue < 200) {
        return E.left(SpaceDimensionsDialogError.UNDER_MINIMUM_DIMENSION);
    }
    else if (numericValue > 1200) {
        return E.left(SpaceDimensionsDialogError.OVER_MAXIMUM_DIMENSION);
    }
    else {
        return E.right(numericValue);
    }
};
var modelErrorFromError = function (which) { return function (error) {
    return ({ value: which, error: error });
}; };
var parse = function (width, length) {
    var _width = (0, function_1.pipe)(parseValue(width), E.mapLeft(modelErrorFromError('width')));
    var _length = (0, function_1.pipe)(parseValue(length), E.mapLeft(modelErrorFromError('length')));
    return (0, function_1.pipe)(_width, E.mapLeft(function (widthError) {
        return (0, function_1.pipe)(_length, 
        // if both width and length result in an error, then we want to include them both in the Left
        E.mapLeft(function (lengthError) { return [widthError, lengthError]; }), E.match(function (errors) { return errors; }, 
        // if only width is an error, we don't want to return length - we want to return the width error
        function (_) { return [widthError]; }));
    }), E.chain(function (width) {
        return (0, function_1.pipe)(_length, 
        // if only length is an error, we don't want to return the width - we want to return the length error
        E.mapLeft(function (lengthError) { return [lengthError]; }), 
        // neither width or length is an error
        E.chain(function (length) { return E.right({ width: width, length: length }); }));
    }));
};
exports.parse = parse;
function uiMessageFromError(error) {
    switch (error) {
        case SpaceDimensionsDialogError.NOT_WHOLE_NUMBER:
            return 'Please enter a whole number';
        case SpaceDimensionsDialogError.UNDER_MINIMUM_DIMENSION:
            return 'Minimum 200cm';
        case SpaceDimensionsDialogError.OVER_MAXIMUM_DIMENSION:
            return 'Maximum 1200cm';
    }
}
exports.uiMessageFromError = uiMessageFromError;
