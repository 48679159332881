"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromString = exports.toString = void 0;
const E = __importStar(require("fp-ts/Either"));
const Err = __importStar(require("@fixtuur/error-context"));
function toString(modelId) {
    return `${modelId.productId}--${modelId.articleType}`;
}
exports.toString = toString;
function fromString(modelId) {
    const segments = modelId.split('--');
    // minimum two segments expected
    if (segments.length < 2) {
        return E.left(Err.construct('less than 2 segments found, this should not be possible', {
            modelId,
            segments: JSON.stringify(segments, null, 2),
        }));
    }
    const last = segments.pop();
    if (last === 'ar-model') {
        return E.right({
            productId: segments.join('--'),
            articleType: last,
        });
    }
    else {
        return E.right({
            productId: segments.join('--'),
            articleType: last,
        });
    }
}
exports.fromString = fromString;
