"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.unwrapSize = exports.selectThumbnailName = exports.generateName = exports.countSeats = exports.placePieces = exports.Interstitials = exports.isCornerStyle = exports.DefaultCornerStyle = exports.CornerStyle = exports.Template = exports.shapes = void 0;
const S = __importStar(require("./modaSofa/shape"));
const Sz = __importStar(require("./modaSofa/size"));
const T = __importStar(require("./modaSofa/template"));
const P = __importStar(require("./modaSofa/piece"));
const I = __importStar(require("./modaSofa/interstitials"));
const fine_1 = require("@digital-bridge/fine");
exports.shapes = S.shapes;
exports.Template = T;
exports.CornerStyle = T.CornerStyle;
exports.DefaultCornerStyle = T.defaultCornerStyle;
exports.isCornerStyle = T.isCornerStyle;
exports.Interstitials = I.Interstitials;
/**
 * Construct a modular sofa from a sequence of piece placements, starting from
 * the 'left'-most piece.
 *
 * Standard sofas will be placed relative to the left-most corner piece, or the
 * left-most piece if no corner piece is present.
 * Curved sofas will be placed relative to the middle of the sofa's curve.
 */
function placePieceSequence(templates) {
    return (pieces, cornerStyle) => {
        let currentPosition = new fine_1.Position2D(0, 0);
        let currentOrientation = 0;
        const placed = [];
        for (let piece of pieces) {
            let template;
            // Select the correct template based on the piece kind and sofa kind.
            switch (piece) {
                case 'corner':
                    template =
                        templates.kind === 'standard' && cornerStyle
                            ? templates.corner[cornerStyle]
                            : undefined;
                    break;
                case 'sofa-end-left':
                    template = templates.end.left;
                    break;
                case 'sofa-end-right':
                    template = templates.end.right;
                    break;
                case 'straight':
                    template =
                        templates.kind === 'standard' ? templates.straight : templates.middle;
                    break;
                case 'drinks-cooler-armrest':
                    template = templates.interstitials.drinksCoolerArmrest;
                    break;
            }
            if (template === undefined) {
                throw new Error(`No valid sofa template for ${piece} piece with ${templates.kind} placement`);
            }
            let placementPosition, placementOrientation;
            ({ placementPosition, placementOrientation, currentOrientation, currentPosition } =
                templates.kind === 'standard'
                    ? placeStraightPiece(template, currentPosition, currentOrientation, piece)
                    : placeCurvedPiece(template, currentPosition, currentOrientation, piece));
            placed.push({
                ...template,
                kind: piece,
                position: placementPosition,
                orientation: fine_1.Orientation3D.fromAxisAngle(fine_1.Vector3D.unitY(), -placementOrientation),
            });
        }
        // We initially placed all sofa pieces relative to the first piece -
        // correct the position and orientation of all pieces depending on
        // whether the sofa is standard or curved.
        switch (templates.kind) {
            case 'standard':
                const firstCorner = placed.find(piece => piece.kind === 'corner');
                if (firstCorner) {
                    repositionSofa(firstCorner.position, firstCorner.orientation, placed);
                }
                break;
            case 'curved':
                const pre = placed[Math.floor((placed.length - 1) / 2)];
                const post = placed[Math.ceil((placed.length - 1) / 2)];
                const midpoint = pre.position.translate(post.position.subtract(pre.position).scale(0.5));
                const [aw, ax, ay, az] = pre.orientation.quaternion();
                const [bw, bx, by, bz] = post.orientation.quaternion();
                // TODO: Use spherical linear interpolation here - this simple
                // linear interpolation of components works well enough for
                // the typical orientations involved for current sofas,
                // but could behave poorly in some cases.
                const midOrientation = fine_1.Orientation3D.fromQuaternion([
                    (aw + bw) / 2,
                    (ax + bx) / 2,
                    (ay + by) / 2,
                    (az + bz) / 2,
                ]).normalise();
                repositionSofa(midpoint, midOrientation, placed);
                break;
        }
        return placed;
    };
}
function placeStraightPiece(template, currentPosition, currentOrientation, piece) {
    const dimensions = template.dimensions;
    const adjustments = template.dimensionAdjustments ?? {
        width: { left: 0, right: 0 },
        depth: { front: 0, back: 0 },
    };
    const { localX, localZ } = sofaPieceBasis(currentOrientation);
    let placementOffset;
    let placementPosition;
    const nextPositionOffset = adjustments.width.left + adjustments.width.right + dimensions.width;
    const piecePlacementOffset = adjustments.width.left + dimensions.width / 2;
    if (piece === 'corner') {
        placementOffset = localX
            .scale(adjustments.depth.front + dimensions.depth / 2)
            .sum(localZ.scale(piecePlacementOffset));
        placementPosition = currentPosition.translate(placementOffset);
        currentOrientation += Math.PI / 2;
        const cornerPositionOffset = adjustments.depth.back + adjustments.depth.front + dimensions.depth;
        currentPosition = currentPosition.translate(localX.scale(cornerPositionOffset).sum(localZ.scale(nextPositionOffset)));
    }
    else {
        placementOffset = localX
            .scale(piecePlacementOffset)
            .sum(localZ.scale(adjustments.depth.back + dimensions.depth / 2));
        placementPosition = currentPosition.translate(placementOffset);
        currentPosition = currentPosition.translate(localX.scale(nextPositionOffset));
    }
    return {
        placementPosition,
        placementOrientation: currentOrientation,
        currentOrientation,
        currentPosition,
    };
}
/**
 * Place a curved sofa piece
 *
 * Although currently 'curved' and 'standard' sofas are controlled at the overall
 * "sofa templates" level rather than by an individual piece's template, placement
 * logic for both is consistent. The "current position" for both pieces in either
 * kind of sofa is the back-left of the sofa piece itself and the individual
 * placement functions correctly return a new position and orientation at the
 * back-right of the placed piece. This means, in future, curved and standard
 * placement pieces _could_ be mixed in one set of templates without substantial
 * change to either placement function.
 *
 * A diagram for curved piece placement can be found in Lucid Chart:
 * https://lucid.app/lucidchart/09033d75-42f2-4454-add0-f2a2c913c261/edit?page=I-paaAvgtq9A
 */
function placeCurvedPiece(template, currentPosition, currentOrientation, piece) {
    const dimensions = template.dimensions;
    const adjustments = template.dimensionAdjustments;
    const startVec = new fine_1.Vector2D([Math.sin(currentOrientation), -Math.cos(currentOrientation)]);
    const curveCentre = currentPosition.translate(startVec.scale(-dimensions.radius));
    const endOrientation = currentOrientation + (dimensions.angularSize * Math.PI) / 180;
    const endVec = new fine_1.Vector2D([Math.sin(endOrientation), -Math.cos(endOrientation)]);
    const endPosition = curveCentre.translate(endVec.scale(dimensions.radius));
    const placementOrientation = (currentOrientation + endOrientation) / 2;
    const placementVec = new fine_1.Vector2D([
        Math.sin(placementOrientation),
        -Math.cos(placementOrientation),
    ]);
    const placementPosition = curveCentre.translate(placementVec.scale(dimensions.radius + adjustments?.radiusOffset));
    return {
        placementPosition,
        placementOrientation,
        currentOrientation: endOrientation,
        currentPosition: endPosition,
    };
}
/**
 * Calculate a sofa piece's local x and z vectors in the sofa's coordinate space
 * given the current piece orientation
 */
function sofaPieceBasis(currentOrientation) {
    const localX = new fine_1.Vector2D([Math.cos(currentOrientation), Math.sin(currentOrientation)]);
    const localZ = new fine_1.Vector2D([-localX.value()[1], localX.value()[0]]);
    return { localX, localZ };
}
/**
 * Correct sofa pieces' positions and orientations to make the supplied position and orientation
 * the new default pose
 */
function repositionSofa(position, rotation, placed) {
    const rotationFix = rotation.inverse();
    for (let placedPiece of placed) {
        placedPiece.orientation = rotationFix.compose(placedPiece.orientation);
        const difference = placedPiece.position.subtract(position).value();
        const difference3d = new fine_1.Vector3D([difference[0], 0, difference[1]]);
        const corrected3d = rotationFix.rotateVector(difference3d);
        placedPiece.position = new fine_1.Position2D(0, 0).translate(corrected3d.xz());
    }
}
/**
 * construct a modular furniture representation as a collection of SKUs with world space positions
 * and orientations. This is derived from the data of a Configuration.
 *
 * This function can throw, but should not throw if the correct types are met.
 *
 * The construction of a sofa follows the description laid out here:
 *      https://docs.google.com/document/d/1qygOxVp0Rswp8IrJTYEZwdWbugSqsOmF7gw5taWYtUI/edit
 *
 * @param templates - The actual templates to use to construct the sofa pieces, generally this is
 * pulled out of the store keyed on configuration.templateKey
 */
function placePieces(templates) {
    const placePieces = placePieceSequence(templates);
    return (size, interstitialPlacements, cornerStyle) => {
        let pieces;
        // Generate a sequence of piece kinds to place for `placePieceSequence`
        switch (templates.kind) {
            case 'standard':
                switch (size.shape) {
                    case 'straight':
                        pieces = [
                            'sofa-end-left',
                            ...new Array(size.middle).fill('straight'),
                            'sofa-end-right',
                        ];
                        break;
                    case 'corner':
                        pieces = [
                            'sofa-end-left',
                            ...new Array(size.left).fill('straight'),
                            'corner',
                            ...new Array(size.right).fill('straight'),
                            'sofa-end-right',
                        ];
                        break;
                    case 'u-shape':
                        pieces = [
                            'sofa-end-left',
                            ...new Array(size.left).fill('straight'),
                            'corner',
                            ...new Array(size.middle).fill('straight'),
                            'corner',
                            ...new Array(size.right).fill('straight'),
                            'sofa-end-right',
                        ];
                        break;
                }
                break;
            case 'curved':
                pieces = [
                    'sofa-end-left',
                    ...new Array('middle' in size ? size.middle : 0).fill('straight'),
                    'sofa-end-right',
                ];
                break;
        }
        // Place interstitials according to their placement indices
        pieces = pieces.flatMap((value, i) => {
            const interstitials = [];
            for (let [interstitialKind, interstitial] of Object.entries(interstitialPlacements)) {
                if (interstitial.placements.includes(i)) {
                    interstitials.push(interstitialKind);
                }
            }
            return [value, ...interstitials];
        });
        return placePieces(pieces, cornerStyle);
    };
}
exports.placePieces = placePieces;
/**
 * Counts the number of seats in a Sofa.
 *
 * Note that the minimum number of seats in a sofa is 4 due if the end pieces are of size 2.
 */
const countSeats = (templates, config) => {
    let seatsPerStraight, seatsPerCorner, seatsPerEnds;
    switch (templates.kind) {
        case 'standard':
            const cornerTemplate = templates.corner[config.cornerStyle];
            // TODO: upgrade to Either<ErrorContext<SofaError>, blah> and handle correctly
            if (cornerTemplate === undefined) {
                console.error(`Unable to count seats: templates have no entry for corner style ${config.cornerStyle}`);
                return 0;
            }
            seatsPerCorner = cornerTemplate.numberOfSeats;
            seatsPerStraight = templates.straight.numberOfSeats;
            seatsPerEnds = templates.end.left.numberOfSeats + templates.end.right.numberOfSeats;
            break;
        case 'curved':
            seatsPerStraight = templates.middle.numberOfSeats;
            seatsPerCorner = 0;
            seatsPerEnds = templates.end.left.numberOfSeats + templates.end.right.numberOfSeats;
            break;
    }
    switch (config.size.shape) {
        case 'straight':
            // extra: end pieces
            return config.size.middle * seatsPerStraight + seatsPerEnds;
        case 'u-shape':
            // extra: end pieces + 2 corner pieces
            return (config.size.left * seatsPerStraight +
                config.size.middle * seatsPerStraight +
                config.size.right * seatsPerStraight +
                2 * seatsPerCorner +
                seatsPerEnds);
        case 'corner':
            // extra: end pieces + corner piece
            return (config.size.left * seatsPerStraight +
                config.size.right * seatsPerStraight +
                1 * seatsPerCorner +
                seatsPerEnds);
    }
};
exports.countSeats = countSeats;
const generateName = (templateKind, config, range) => {
    if (templateKind === 'curved')
        return `${range} Custom Sofa`;
    switch (config.size.shape) {
        case 'straight':
            return `${range} Custom Straight Sofa`;
        case 'u-shape':
            return `${range} Custom U-Shaped Sofa`;
        case 'corner':
            return `${range} Custom Corner Sofa`;
    }
};
exports.generateName = generateName;
const selectThumbnailName = (templateKind, config) => {
    return `${templateKind === 'curved' ? 'curved' : config.size.shape}.svg`;
};
exports.selectThumbnailName = selectThumbnailName;
/**
 * Given a ModaSofa Size object, export a LayoutModularFurniture size object.
 * This strips the shape key from the representation and enforces a non-null number.
 * @param size from modaSofa's representation
 */
function unwrapSize(size) {
    return {
        left: size.shape === 'corner' || size.shape === 'u-shape' ? size.left : 0,
        right: size.shape === 'corner' || size.shape === 'u-shape' ? size.right : 0,
        middle: size.shape === 'straight' || size.shape === 'u-shape' ? size.middle : 0,
    };
}
exports.unwrapSize = unwrapSize;
