"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGlobals = exports.getPropertyMap = exports.finishesEqual = exports.ConfigurablePropertySelection = exports.Gift = exports.isFinish = exports.Finish = void 0;
const t = __importStar(require("io-ts"));
const E = __importStar(require("fp-ts/lib/Either"));
/** Currently we only need to support Finish properties  */
exports.Finish = t.intersection([
    t.type({
        name: t.string,
    }),
    t.partial({
        colour: t.string,
        image: t.string,
    }),
]);
const isFinish = (f) => {
    return E.isRight(exports.Finish.decode(f));
};
exports.isFinish = isFinish;
exports.Gift = t.record(t.string, t.type({ isGlobal: t.boolean, label: t.string, type: t.string }));
exports.ConfigurablePropertySelection = t.type({
    propertyKey: t.string,
    finish: exports.Finish,
});
const finishesEqual = (a, b) => {
    const coloursEqual = a.colour && b.colour ? a.colour === b.colour : true;
    const imagesEqual = a.image && b.image ? a.image === b.image : true;
    return a.name === b.name && coloursEqual && imagesEqual;
};
exports.finishesEqual = finishesEqual;
const getPropertyMap = (giftConfigurableProperties) => {
    return Object.entries(giftConfigurableProperties).reduce((props, [key, value]) => {
        if (!props[key]) {
            props[key] = value.label;
        }
        return props;
    }, {});
};
exports.getPropertyMap = getPropertyMap;
const getGlobals = (giftConfigurableProperties) => {
    return Object.keys(giftConfigurableProperties);
};
exports.getGlobals = getGlobals;
