"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Logging = exports.LogLevel = void 0;
var LogLevel;
(function (LogLevel) {
    LogLevel[LogLevel["trace"] = 0] = "trace";
    LogLevel[LogLevel["debug"] = 1] = "debug";
    LogLevel[LogLevel["info"] = 2] = "info";
    LogLevel[LogLevel["warning"] = 3] = "warning";
    LogLevel[LogLevel["error"] = 4] = "error";
})(LogLevel = exports.LogLevel || (exports.LogLevel = {}));
const DEFAULT_LOG_LEVEL = LogLevel.info;
class Logging {
    static shouldLog(logLevel) {
        return logLevel >= this._logLevel;
    }
    /**
     * Convert an enum to a string suitable for logging.
     * @param logLevel
     * @returns string
     */
    static logLevelToString(logLevel) {
        switch (logLevel) {
            case LogLevel.trace:
                return '[TRACE] ';
            case LogLevel.debug:
                return '[DEBUG] ';
            case LogLevel.info:
                return '[INFO ] ';
            case LogLevel.warning:
                return '[WARN ] ';
            case LogLevel.error:
                return '[ERROR] ';
        }
    }
    static logMessage(message, logLevel) {
        if (this.shouldLog(logLevel)) {
            if (message.indexOf(this.LOGPREFIX) === -1) {
                message = this.LOGPREFIX + message;
            }
            const logMessage = this.logLevelToString(logLevel) + message;
            console.log(JSON.parse(JSON.stringify(logMessage)));
        }
    }
}
exports.Logging = Logging;
Logging._logLevel = DEFAULT_LOG_LEVEL;
Logging.LOGPREFIX = '[CONDUIT] ';
