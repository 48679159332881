"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Conduit = void 0;
const tslib_1 = require("tslib");
const feature_flags_1 = require("./feature-flags");
tslib_1.__exportStar(require("./PaginatedList"), exports);
tslib_1.__exportStar(require("./Network"), exports);
tslib_1.__exportStar(require("./VendorMetaData"), exports);
tslib_1.__exportStar(require("./Vector3"), exports);
tslib_1.__exportStar(require("./Quaternion"), exports);
tslib_1.__exportStar(require("./Triple"), exports);
tslib_1.__exportStar(require("./assets"), exports);
tslib_1.__exportStar(require("./designs"), exports);
tslib_1.__exportStar(require("./showrooms"), exports);
tslib_1.__exportStar(require("./catalogueImagery"), exports);
tslib_1.__exportStar(require("./feature-flags"), exports);
tslib_1.__exportStar(require("./Logger"), exports);
tslib_1.__exportStar(require("./DigitalBridgeTransport"), exports);
tslib_1.__exportStar(require("./language/declarations"), exports);
tslib_1.__exportStar(require("./catalogue"), exports);
tslib_1.__exportStar(require("./configurations"), exports);
tslib_1.__exportStar(require("./common"), exports);
const featuresDefault = '9d05bf73vdu4l0vkr23pb0hut047ckkvbh6b'; // prod default read-only key
const defaultOptions = {
    assetAPIURL: '',
    designsAPIURL: '',
    showroomsAPIURL: '',
    snowflakeAPIURL: '',
    catalogueAPIURL: '',
    catalogueImageryAPIURL: '',
    featureFlagOptions: {
        environmentKey: featuresDefault,
    },
};
class Conduit {
    constructor(_network, options) {
        this._network = _network;
        this._featureFlags = null;
        this._options = Object.assign(defaultOptions, options);
    }
    featureFlags() {
        if (!this._featureFlags) {
            const featureFlagService = new feature_flags_1.SplitIOFeatureService(this._options.featureFlagOptions);
            this._featureFlags = new feature_flags_1.FeatureFlags(this._network, featureFlagService);
        }
        return this._featureFlags;
    }
}
exports.Conduit = Conduit;
