import UploadFile from '@mui/icons-material/UploadFile';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/Option';
import * as Preact from 'preact';

/** not great, but hey */
const isHtmlInput = (e: any): e is HTMLInputElement => e != null && e.files !== undefined;

export interface Props {
    disabled: boolean;
    onClose: () => void;
    onFilesReady: (fs: File[]) => void;
    open: boolean;
}

/** Menu to load files via the browser dialogue or drag-and-drop */
export const Component = (p: Props) => {
    return (
        <Dialog open={p.open} onClose={p.onClose}>
            <div
                id="file_drop"
                onDrop={e => {
                    e.preventDefault();
                    const files = e.dataTransfer?.files;

                    if (files) {
                        p.onClose();
                        p.onFilesReady(Array.from(files));
                    }
                }}
                onDragOver={e => e.preventDefault()}
            >
                <Box
                    sx={{
                        p: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Button component="label" disabled={p.disabled}>
                        <UploadFile
                            sx={{
                                color: 'primary.main',
                                fontSize: 72,
                                mb: '44px',
                            }}
                        />
                        <input
                            type="file"
                            hidden
                            accept=".glb"
                            multiple
                            disabled={p.disabled}
                            onChange={e => {
                                pipe(
                                    e.target,
                                    O.fromNullable,
                                    O.filter(isHtmlInput),
                                    O.chain(input => pipe(input.files, O.fromNullable)),
                                    O.map(files => {
                                        p.onFilesReady(Array.from(files));
                                    }),
                                );
                            }}
                        />
                    </Button>
                    <Typography variant="h5">Click or drag to upload your models</Typography>
                </Box>
            </div>
        </Dialog>
    );
};
