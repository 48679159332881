import { LogLevel } from '@digital-bridge/artisan';
import { AryaAdaptor } from '@digital-bridge/artisan-arya';
import { Position2D, Vector2D } from '@digital-bridge/fine';
import * as Hooks from 'preact/hooks';
import { useImperativeInterface } from './imperativeInterface';

/** Initialise and return an instance of Arya directly */
export const useArya = (
    canvas3d: Hooks.MutableRef<HTMLCanvasElement>,
    canvas2d: Hooks.MutableRef<HTMLCanvasElement>,
) => {
    const [arya, setArya] = Hooks.useState<null | AryaAdaptor>(null);

    Hooks.useEffect(() => {
        let _arya: undefined | AryaAdaptor = undefined;

        const c2d = canvas2d.current;
        const c3d = canvas3d.current;

        if (c2d === null || c3d === null) {
            return () => {};
        }

        _arya = new AryaAdaptor(
            {
                retrieveModel: async (id: string) => {
                    return {
                        canonicalId: id,
                        parameters: {
                            schemaVersion: '0',
                            aabb: { min: { x: 0, y: 0, z: 0 }, max: { x: 0, y: 0, z: 0 } },
                        },
                        modelFile: new ArrayBuffer(0),
                    };
                },
                retrieveCovering: async (id: string) => {
                    return {
                        canonicalId: id,
                        parameters: { schemaVersion: '0', width: 1, height: 1 },
                        modelFile: new ArrayBuffer(0),
                    };
                },
            },
            c3d,
            c2d,
            {
                assetPaths: {
                    rotationHandles: 'assets/rotationHandles/rothandles-large.png',
                    rotationHandlesSmall: 'assets/rotationHandles/rothandles-small.png',
                },
                themingOptions: {
                    placeholder: {
                        backgroundColour: '#000000',
                        foregroundColour: '#FFFFFF',
                    },
                    lightingMode: 'outdoor',
                },
                enableFurniturePlaceholders: false,
                logLevel: LogLevel.debug,
                topDownViewConfig: {
                    wallThickness: 0.025,
                    font: {
                        size: 16,
                        family: 'Arial',
                        colour: '#FF00FF',
                    },
                    box: { padding: 10, colour: 'black', lineWidth: 1 },
                    unit: {
                        fromMeters: (meters: number) => `${meters} m`,
                    },
                },
                dynamicResolutionScaling: true,
                roomFeatures2D: false,
                initialViewMode: '3D',
                obnoxiousFailure: true,
                logarithmicDepthBuffer: true,
                showDebugRenderInfo: true,
                enableSky: false,
                useRenderRequiredFlag: true,
                showDebugGui: true,
                showProductDistances: true,
                enableCollisionDetection: false,
                enableClampToCeiling: false,
                enableRegionSelection: false,
                environmentMap: {
                    path: 'assets/',
                    lightEnvMapFilename: 'lightEnvMap.hdr',
                    skyEnvMapFilename: 'skyEnvMap.png',
                },
                forceWebGL1: false,
            },
        );

        const roomSize = 6;
        _arya.loadRoom(
            {
                height: 0.001,
                vertices: [
                    new Position2D(0, 0),
                    new Position2D(0, roomSize),
                    new Position2D(roomSize, roomSize),
                    new Position2D(roomSize, 0),
                ]
                    // center room on 0,0 (so naive furniture adding works)
                    .map(p => p.translate(new Vector2D([-roomSize / 2, -roomSize / 2]))),
            },
            [],
        );

        setArya(_arya);

        return () => {
            _arya?.shutdown();
        };
    }, [canvas2d, canvas3d]);

    return useImperativeInterface(arya);
};
