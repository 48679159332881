"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.request = void 0;
const function_1 = require("fp-ts/lib/function");
const TE = __importStar(require("fp-ts/TaskEither"));
const Err = __importStar(require("@fixtuur/error-context"));
const requestError_1 = require("./requestError");
const request = (url) => (p) => {
    return (0, function_1.pipe)(TE.Do, TE.bind('response', () => TE.tryCatch(() => fetch(url, {
        method: 'PUT',
        body: p.file,
        headers: {
            'Content-Disposition': `attachment;  filename=${p.fileName}`,
        },
    }), err => Err.construct(requestError_1.RequestError.FetchError, {
        error: err?.message,
        url,
    }))), TE.chain(original => {
        const { response } = original;
        if (!response.ok) {
            return TE.left(Err.construct(requestError_1.RequestError.NoHttpSuccessCodeError, {
                responseUrl: response.url,
                responseStatus: response.status.toString(10),
                responseStatusText: response.statusText,
            }));
        }
        return TE.right(original);
    }), TE.map(_ => true));
};
exports.request = request;
