"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaginatedList = void 0;
const tslib_1 = require("tslib");
const Network_1 = require("./Network");
/**
 * Paginated Array class for returning list data from the API.
 * Can retrieve more objects on request, resolved via Promise<Array<T>>, but this objects' internal
 * array will be updated too so the front end doesn't need to maintain its own data.
 */
class PaginatedList {
    /**
     * Build a paginated objects array
     * @param authHandler used to fetch next and previous pages
     * @param paginatedJson the raw json from a paginated list endpoint
     * @param countPerPage used to calculate total number of pages for front end
     * @param objectConstructor a closure representing a constructor for the object utilising individual result json
     */
    constructor(authHandler, paginatedJson, countPerPage, objectConstructor) {
        this.authHandler = authHandler;
        this.paginatedJson = paginatedJson;
        this.countPerPage = countPerPage;
        this.objectConstructor = objectConstructor;
        this._objects = [];
        this._countTotal = 0;
        this._countTotal = paginatedJson.count;
        // build objects array from injected constructor closure
        for (const result of paginatedJson.results) {
            this.objects.push(objectConstructor(result));
        }
    }
    static formatListUrl(baseUrl, objectsPerPage = 20, page = 1, ordering = '-modified') {
        return (baseUrl +
            '?page=' +
            page +
            '&page_size=' +
            objectsPerPage +
            '&ordering=' +
            ordering);
    }
    static isPaginatedJson(json) {
        return 'results' in json;
    }
    /**
     * Get the total number of possible objects from the requested list (not just currently fetched)
     * @returns {number}
     */
    get countTotal() {
        return this._countTotal;
    }
    /**
     * Returns the total number of pages in this request. Useful for UI.
     * @returns {number}
     */
    get pagesTotal() {
        return Math.ceil(this._countTotal / this.countPerPage);
    }
    /**
     * Returns the current array of SBObjects. When a fetchNext() promise has resolved, the result will be appended
     * to this array if you do not wish to manually manage the objects.
     * @returns {Array<DBAPIJSON>}
     */
    get objects() {
        return this._objects;
    }
    /**
     * Fetch the previous page as a new PaginatedSBObjects array, allowing you to discard the existing one.
     * @returns {Promise<PaginatedList>}
     * @throws {e} Throws an exception if there is no prev page to fetch. Guard against with paginatedList.hasPrev().
     */
    fetchPrev() {
        return new Promise((resolve, reject) => {
            if (this.paginatedJson.previous) {
                const request = new Network_1.Request(this.paginatedJson.previous);
                resolve(this.authHandler.executeRequest(request));
            }
            else {
                reject('No previous page to fetch.');
            }
        }).then((paginatedResponse) => new PaginatedList(this.authHandler, paginatedResponse, this.countPerPage, this.objectConstructor));
    }
    /**
     * Fetch the next page as a new PaginatedSBObjects array, allowing you to discard the existing one.
     * @returns {Promise<PaginatedList>}
     * @throws {e} Throws an exception if there is no next page to fetch. Guard against with paginatedList.hasNext().
     */
    fetchNext() {
        return new Promise((resolve, reject) => {
            if (this.paginatedJson.next) {
                const request = new Network_1.Request(this.paginatedJson.next);
                resolve(this.authHandler.executeRequest(request));
            }
            else {
                reject('No next page to fetch.');
            }
        }).then((paginatedResponse) => {
            return new PaginatedList(this.authHandler, paginatedResponse, this.countPerPage, this.objectConstructor);
        });
    }
    /**
     * Fetch the next logical set of objects in the sequence.
     * On resolution, this object will append the result to its existing array.
     * If you prefer to manage that client-side, listen for the return of an array of new elements only.
     * @returns {Promise<Array<DBAPIJSON>>}
     * @throws {e} Throws an exception if there are no more results to fetch. Guard against with paginatedList.hasMore().
     */
    fetchMore() {
        return new Promise((resolve, reject) => {
            if (this.paginatedJson.next) {
                const request = new Network_1.Request(this.paginatedJson.next);
                resolve(this.authHandler.executeRequest(request));
            }
            else {
                reject('No more results to fetch.');
            }
        }).then((paginatedResponse) => {
            // build new paginated array of returned objects
            const newResults = new PaginatedList(this.authHandler, paginatedResponse, this.countPerPage, this.objectConstructor);
            // append current results with new ones
            this._objects = this._objects.concat(newResults.objects);
            // overwrite existing json with new one
            this.paginatedJson = paginatedResponse;
            // return the newly created objects
            return newResults.objects;
        });
    }
    /**
     * Recursively hit every next url and fetch every possible element. Should only be used is niche use-cases and
     * extremely sparingly as this may result in a poor user experience waiting for all this data!
     * @param limit sanity check, will throw an exception if the amount of elements to be fetched exceeds this value
     * @throws {e} Throws an exception if fetching all elements would exceed the limit param.
     */
    fetchAll(limit = 50) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.countTotal > limit) {
                throw 'fetching all elements would exceed the limit';
            }
            while (this.hasMore()) {
                yield this.fetchMore();
            }
            return this.objects;
        });
    }
    /**
     * Returns true if there's a prev page to fetch
     * @returns {boolean}
     */
    hasPrev() {
        return (this.paginatedJson.previous !== null &&
            this.paginatedJson.previous !== undefined);
    }
    /**
     * Returns true of there's a next page to fetch
     * @returns {boolean}
     */
    hasNext() {
        return (this.paginatedJson.next !== null &&
            this.paginatedJson.next !== undefined);
    }
    /**
     * Return true if current number of objects stored is less than total count returned from API.
     * Useful when using fetchMore() to lazily load as the user scrolls.
     * @returns {boolean}
     */
    hasMore() {
        return this.objects.length < this._countTotal;
    }
}
exports.PaginatedList = PaginatedList;
