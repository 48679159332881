import { themes } from '@dora/components';
import { ThemeProvider } from '@mui/material/styles';
import * as Preact from 'preact';
import Router, { route } from 'preact-router';
import { pathUrl } from '../environment';
import * as ProductPageWrapper from './ProductPageWrapper';
import * as Header from './header';
import * as ModelTestPage from './modelTestPage';

const App = () => {
    // const openProductPage = (id: string) => {
    //     route(`/products/${id}`);
    // };

    const openModelChecker = () => {
        route('/');
    };

    return (
        <>
            <Header.Component />
            <ThemeProvider theme={themes.getTheme()}>
                <Router>
                    <ModelTestPage.Component path={pathUrl('/')} />
                    <ProductPageWrapper.Component path={pathUrl('/dashboard')} openModelChecker={openModelChecker}/>
                </Router>
            </ThemeProvider>
        </>
    );
};

export default App;
