"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SplitIOFeatureService = void 0;
const tslib_1 = require("tslib");
const splitio = tslib_1.__importStar(require("@splitsoftware/splitio"));
const feature_flags_1 = require("./feature-flags");
class SplitIOFeatureService {
    constructor(_options) {
        this._options = _options;
        this._state = feature_flags_1.FeatureFlagServiceState.Uninitialised;
        this._user = 'no-user';
        this._userAttributes = {};
        this._options.developmentFlags = this._options.developmentFlags || {};
    }
    /** Initialise the service and fetch features into local store */
    initialise() {
        this._splitioFactory = splitio.SplitFactory({
            core: {
                authorizationKey: this._options.environmentKey,
                key: this._user,
            },
            // streaming behaviour (vs. polling) appears to interfere with PWA service workers and prevent updates.
            streamingEnabled: false,
        });
        const client = this._splitioFactory.client();
        this.state = feature_flags_1.FeatureFlagServiceState.Initialising;
        client.on(client.Event.SDK_READY, () => {
            this.state = feature_flags_1.FeatureFlagServiceState.Ready;
        });
        client.on(client.Event.SDK_READY_TIMED_OUT, () => {
            this.state = feature_flags_1.FeatureFlagServiceState.TimedOut;
        });
    }
    /** Gracefully shut down the service */
    shutdown() {
        this.state = feature_flags_1.FeatureFlagServiceState.Uninitialised;
        if (this._splitioFactory) {
            return this._splitioFactory.client().destroy();
        }
        return Promise.resolve();
    }
    /**
     * Get or set the current user attributes.
     * this will be used to send with split requests.
     */
    set userAttributes(attributes) {
        this._userAttributes = attributes;
    }
    get userAttributes() {
        return this._userAttributes;
    }
    /**
     * Get or set the current user as the split bucket key or customer id.
     * This is used to uniquely identify the request and counts as 1 monthly active user.
     * It should be used to ensure users get a consistent experience across multiple devices.
     * Beware that a guest user transitioning to an authenticated user may modify this key!
     */
    get user() {
        return this._user;
    }
    set user(user) {
        this._user = user;
    }
    /** get or set the state of the service */
    get state() {
        return this._state;
    }
    set state(state) {
        this._state = state;
    }
    /** Retrieve a list of all available feature flags */
    flags() {
        return this._splitioFactory.manager().names();
    }
    /** Returns the Split treatment configurations of a given feature flag */
    getFlagValueConfigurations(flagName, value) {
        const configs = this._splitioFactory.manager().split(flagName).configs[value];
        return JSON.parse(configs || '{}');
    }
    /** Returns whether a given flag has a boolean response */
    isFlagBoolean(flagName) {
        const response = this._splitioFactory
            .client()
            .getTreatment(flagName, this.userAttributes);
        return (response === feature_flags_1.BooleanFlagResponse.On ||
            response === feature_flags_1.BooleanFlagResponse.Off ||
            response === feature_flags_1.BooleanFlagResponse.Default);
    }
    /**
     * Retrieve a single boolean flag.
     * If the requested flag is multi-variant, default will be returned.
     **/
    getBooleanFlagValue(flagName) {
        return this.getMultiVariantFlag(flagName);
    }
    /**
     * Retrieve multiple boolean flags.
     * If a requested flag is multi-variant, default will be returned.
     **/
    getMultipleBooleanFlags(flagNames) {
        const { developmentFlags } = this._options;
        const evaluate = flagNames.filter(flagName => this.isFlagBoolean(flagName));
        const noBooleanFlags = flagNames.filter(flagName => !this.isFlagBoolean(flagName));
        if (noBooleanFlags.length > 0) {
            console.warn(`Feature Flags ${noBooleanFlags.join(', ')} are not boolean`);
        }
        return Object.assign(Object.assign({}, evaluate
            .filter(flagName => flagName in developmentFlags)
            .reduce((acc, curr) => (Object.assign(Object.assign({}, acc), { [curr]: developmentFlags[curr] })), {})), this._splitioFactory.client().getTreatments(evaluate.filter(flagName => !(flagName in developmentFlags)), this.userAttributes));
    }
    /**
     * Retrieve a multi-variant flag - that is, a flag which contains string responses instead of on or off.
     * If the requested feature is boolean, the raw text response will be returned ('on', 'off', 'control').
     */
    getMultiVariantFlag(flagName) {
        const { developmentFlags } = this._options;
        if (flagName in developmentFlags) {
            return developmentFlags[flagName];
        }
        else {
            const value = this._splitioFactory
                .client()
                .getTreatment(flagName, this.userAttributes);
            if (value === 'control') {
                throw new Error(`Feature ${flagName} does not exist in this environment!`);
            }
            return value;
        }
    }
    /**
     * Track an event in the split service.
     * @param eventName the metric to track - this will appear in the split dashboard once tracked.
     * @param value optional number to attribute to the metric (e.g. seconds taken to load x).
     * @param properties optional string / number / boolean dictionary used to filter metrics in the dashboard.
     */
    trackEvent(eventName, value, properties) {
        this._splitioFactory
            .client()
            .track('user', eventName, value, properties);
    }
    addUpdateListener(callback) {
        const client = this._splitioFactory.client();
        const updateEvent = client.Event.SDK_UPDATE;
        client.on(updateEvent, callback);
    }
    destroyUpdateListener(callback) {
        const client = this._splitioFactory.client();
        const updateEvent = client.Event.SDK_UPDATE;
        client.off(updateEvent, callback);
    }
}
exports.SplitIOFeatureService = SplitIOFeatureService;
