import * as Preact from 'preact';
import List from '@mui/material/List';
import * as ControlCard from './controlPanel/controlCard';

export interface Props {
    onViewOriginClick: () => void;
    onViewBackfaceClick: () => void;
}

export const Component = (p: Props) => {
    return (
        <List>
            <ControlCard.Component title="View Origin" onControlClick={p.onViewOriginClick} />
            <ControlCard.Component title="Cull Backface" onControlClick={p.onViewBackfaceClick} />
        </List>
    );
};
