"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.construct = exports.noAdjustment = exports.isCornerStyle = exports.CornerStyle = exports.defaultCornerStyle = void 0;
const t = __importStar(require("io-ts"));
exports.defaultCornerStyle = 'square';
exports.CornerStyle = t.keyof({ angled: null, square: null });
const isCornerStyle = (cornerStyle) => cornerStyle === 'square' || cornerStyle === 'angled';
exports.isCornerStyle = isCornerStyle;
exports.noAdjustment = {
    width: { left: 0, right: 0 },
    depth: { front: 0, back: 0 },
};
const construct = (sku, width, depth, numberOfSeats, widthLeftOffset = 0, widthRightOffset = 0, depthFrontOffset = 0, depthBackOffset = 0) => ({
    sku,
    numberOfSeats,
    dimensions: { width, depth },
    dimensionAdjustments: {
        width: {
            left: widthLeftOffset,
            right: widthRightOffset,
        },
        depth: {
            front: depthFrontOffset,
            back: depthBackOffset,
        },
    },
});
exports.construct = construct;
