/**
 * NOTE: This isn't really a traditional data type, but more function helpers for an object
 *
 * It should be moved to somewhere more suitable, but I'm not sure where it fits.
 *
 * It looks like a data type. There's a type, and a set of functions which act on that type. But
 * these are not functionally pure - Arya is made of side-effects.
 *
 */
import { AryaAdaptor, AryaEvent, Pose } from '@digital-bridge/artisan-arya';

export type Arya = AryaAdaptor;

/** Add simple furnitutre into the scene */
export const addSimpleFurnitureFromGlbAsset =
    (arya: Arya) => async (name: string, glb: ArrayBuffer, pose?: Pose) => {
        const id = await arya.addSimpleFurnitureFromLocalGlbAsset(name, glb);

        if (pose !== undefined) {
            arya.intents().placeFurnitureById(id, pose);
        }

        arya.intents().showFurnitureById(id);

        return id;
    };

export const furniture = (arya: Arya) => () => arya.queries().furniture();

export const furniturePolygonCount = (arya: Arya) => (furnitureId: string) =>
    arya.queries().furniturePolygonCount(furnitureId);

export const furnitureMaterialDetails = (arya: Arya) => (furnitureId: string) =>
    arya.queries().furnitureMaterialDetails(furnitureId);

export const furnitureBoundingBox = (arya: Arya) => (furnitureId: string) =>
    arya.queries().furnitureBoundingBoxById(furnitureId);

export const furniturePose = (arya: Arya) => (furnitureId: string) =>
    arya.queries().furniturePoseById(furnitureId);

export const selectFurniture = (arya: Arya) => (furnitureId: string) =>
    arya.intents().selectFurnitureByFurnitureId(furnitureId);
export const removeFurniture = (arya: Arya) => (furnitureId: string) =>
    arya.intents().deleteFurnitureByFurnitureId(furnitureId);

export const hideBackfaces = (arya: Arya) => () => arya.intents().hideAllFurnitureBackfaces();
export const showBackfaces = (arya: Arya) => () => arya.intents().showAllFurnitureBackfaces();

export const visualiseOrigin = (arya: Arya) => () => arya.intents().visualiseAllFurnitureOrigin();
export const stopVisualiseOrigin = (arya: Arya) => () =>
    arya.intents().stopVisualiseAllFurnitureOrigin();

/** find the furniture IDs of currently selected furniture */
export const selectedFurniture = (arya: Arya) => () => arya.queries().selectedFurnitureIds();

export const updateEnvironment =
    (arya: Arya) =>
    (
        lighting: 'indoor' | 'outdoor',
        path: string,
        lightEnvMapFilename: string,
        skyEnvMapFilename?: string,
    ) =>
        arya.updateEnvironmentAndLighting(lighting, path, lightEnvMapFilename, skyEnvMapFilename);

export const setRoomGeometryVisibility = (arya: Arya) => (visible: boolean) =>
    arya.intents().setRoomGeometryVisibility(visible);

/** Subscribe a handler function to an Event */
export const on = (arya: Arya) => (event: AryaEvent, handler: Function) =>
    arya.events().on(event, handler);

/** Unsubscribe a handler function from an Event */
export const off = (arya: Arya) => (event: AryaEvent, handler: Function) =>
    arya.events().off(event, handler);
