import { identity, pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/Option';
import * as Preact from 'preact';

export interface Props {
    children: O.Option<JSX.Element>;
}
/**
 * Display an Optional component if it exists - otherwise render nothing
 */
export const Component: Preact.FunctionComponent<Props> = (p: Props) =>
    pipe(
        p.children,
        O.match(() => null, identity),
    );
Component.displayName = 'Maybe';
