"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.center = exports.dimensions = exports.union = exports.construct = exports.empty = void 0;
const fine_1 = require("@digital-bridge/fine");
exports.empty = {
    min: new fine_1.Position2D(Infinity, Infinity),
    max: new fine_1.Position2D(-Infinity, -Infinity),
};
function construct(min, max) {
    return { min: min.minimum(max), max: max.maximum(min) };
}
exports.construct = construct;
function union(x, y) {
    return {
        max: x.max.maximum(y.max),
        min: x.min.minimum(y.min),
    };
}
exports.union = union;
function dimensions(bb) {
    return bb.max.subtract(bb.min);
}
exports.dimensions = dimensions;
function center(bb) {
    return new fine_1.Position2D((bb.min.x() + bb.max.x()) / 2, (bb.min.y() + bb.max.y()) / 2);
}
exports.center = center;
