"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extendContext = exports.fromUnknownWithContext = exports.fromUnknown = exports.construct = exports.toString = exports.swap = void 0;
function isErrorContext(x) {
    return (typeof x === 'object' && x !== null && Object.prototype.hasOwnProperty.call(x, 'message'));
}
/**
 * swap the type of error while maintaining context
 * - includes the original error context alongside a `reason` which
 * includes the text of the original error
 */
const swap = (err, context = {}) => (oerr) => construct(err, Object.assign({ reason: oerr.message }, oerr.context, context));
exports.swap = swap;
function toString(p) {
    return `[ErrorContext]: ${p.message}, context: ${JSON.stringify(p.context, null, 2)}`;
}
exports.toString = toString;
function construct(message, context = {}) {
    return {
        message,
        context,
    };
}
exports.construct = construct;
function fromUnknown(e) {
    if (e instanceof Error) {
        try {
            return construct(e.message, { error: JSON.stringify(e) });
        }
        catch (stringifyErr) {
            return construct(e.message, { error: JSON.stringify(e.toString()) });
        }
    }
    else if (isErrorContext(e)) {
        return e;
    }
    else {
        try {
            return construct(JSON.stringify(e));
        }
        catch (stringifyErr) {
            return construct(JSON.stringify(e.toString()));
        }
    }
}
exports.fromUnknown = fromUnknown;
function fromUnknownWithContext(context) {
    return (e) => {
        const parseErr = fromUnknown(e);
        return extendContext(context)(parseErr);
    };
}
exports.fromUnknownWithContext = fromUnknownWithContext;
function extendContext(ctx) {
    return ({ message, context }) => ({
        message,
        context: Object.assign({}, context, ctx),
    });
}
exports.extendContext = extendContext;
