"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequestError = void 0;
var RequestError;
(function (RequestError) {
    RequestError["FetchError"] = "Request to resource/collection failed";
    RequestError["ResponseJsonError"] = "Unable to retrieve json() from fetch response";
    RequestError["ResponseNotArrayError"] = "Object returned by API expected to be an array but is not";
    RequestError["NoHttpSuccessCodeError"] = "Http call did not receive a success response";
    RequestError["NoValidItemsError"] = "Object returned by API has no items of the expected format";
    RequestError["ResponseNotExpectedFormat"] = "Object returned by API was not of the expected type";
})(RequestError = exports.RequestError || (exports.RequestError = {}));
