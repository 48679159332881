import { Pose } from '@digital-bridge/artisan-arya';
import * as Arr from 'fp-ts/Array';
import { flow, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';

/** Connects a Local file with a Furniture ID for Arya */
export type LocalFileReference = {
    file: File;
    furnitureId: null | string;
    /** Whether to load the file into the scene or not */
    loaded: boolean;
    pose: O.Option<Pose>;
};

/** A record of file name to LocalFileReference */
export type LocalFileStore = Record<string, LocalFileReference>;

export const fileNames = (s: LocalFileStore) => Object.keys(s);

export const file = (fileName: string) => (s: LocalFileStore) => pipe(s[fileName], O.fromNullable);

export const furnitureId = (fileName: string) =>
    flow(
        file(fileName),
        O.map(f => f.furnitureId),
        O.chain(O.fromNullable),
    );

export const loaded = (fileName: string) =>
    flow(
        file(fileName),
        O.map(f => f.loaded),
    );

export const fileByFurnitureId = (fId: string) => (s: LocalFileStore) =>
    pipe(
        Object.values(s),
        Arr.findFirst(f => f.furnitureId === fId),
    );

export const fileNameByFurnitureId = (fId: string) =>
    flow(
        fileByFurnitureId(fId),
        O.map(f => f.file.name),
    );

export const fileSizeBytesByFurnitureId = (fId: string) =>
    flow(
        fileByFurnitureId(fId),
        O.map(f => f.file.size),
    );
