import * as Preact from 'preact';
import * as Hooks from 'preact/hooks';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import IconButton from '@mui/material/IconButton';
import * as Dimensions from '../dimensions';

export interface Props {
    title: string;
    onControlClick: () => void;
}

export const Component = (p: Props) => {
    const [active, setActive] = Hooks.useState<'primary' | 'disabled'>('disabled');

    const onToggleClick = () => {
        p.onControlClick();
        setActive(active === 'disabled' ? 'primary' : 'disabled');
    };

    return (
        <ListItemButton
            onClick={onToggleClick}
            disableGutters
            dense
            sx={{
                height: `${Dimensions.itemHeight}px`,
                padding: Dimensions.itemPadding,
            }}
        >
            <ListItemText primaryTypographyProps={{ variant: 'body1' }}>{p.title}</ListItemText>
            <IconButton sx={{ p: '4px' }}>
                <ListItemIcon sx={{ minWidth: 0 }}>
                    <RemoveRedEyeIcon fontSize="medium" color={active} />
                </ListItemIcon>
            </IconButton>
        </ListItemButton>
    );
};
