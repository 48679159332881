import * as Preact from 'preact';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import HelpIcon from '@mui/icons-material/Help';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import Grid from '@mui/material/Grid';
import * as Dimensions from './appBar/dimensions';

export const Component = () => {
    return (
        <AppBar
            color="secondary"
            position="static"
            sx={{
                height: `${Dimensions.appBarHeight}px`,
                paddingX: `${Dimensions.appBarPaddingX}px`,
                paddingY: `${Dimensions.appBarPaddingY}px`,
                flexDirection: 'row',
                alignItems: 'center',
                boxSizing: 'border-box',
            }}
        >
            <Grid container item xs={11}>
                <IconButton color="inherit">
                    <MenuIcon fontSize='small'/>
                </IconButton>
            </Grid>
            <Grid container item xs={1} justifyContent="flex-end">
                <IconButton color="inherit" sx={{ paddingX: '12px' }}>
                    <HelpIcon fontSize="small" />
                </IconButton>
                <IconButton color="inherit" sx={{ paddingX: '12px' }}>
                    <ViewInArIcon fontSize="small" />
                </IconButton>
            </Grid>
        </AppBar>
    );
};
