"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromModelInput = exports.Data = void 0;
const t = __importStar(require("io-ts"));
const base_1 = require("../base");
const Timestamp = __importStar(require("./timestamp"));
const MPK = __importStar(require("./modelPartitionKey"));
/** A complete model item as stored in DynamoDB Models table */
exports.Data = t.intersection([
    t.exact(MPK.ModelPartitionKey),
    t.exact(base_1.ModelIdData),
    t.exact(base_1.ModelDerivedData),
    t.exact(base_1.ModelFiles),
    t.exact(base_1.ModelTenant),
]);
const emptyFile = { location: null, created: null, lastModified: null };
function fromModelInput(m, tenant) {
    const timestamp = Timestamp.create();
    return {
        ...m,
        // start with a 'complete' but empty map of files to make partial updating easier later
        files: {
            attachments: {
                json: emptyFile,
                blend: emptyFile,
            },
            model: { max: emptyFile, glb: emptyFile, blend: emptyFile },
        },
        kind: m.articleType === 'ar-model' ? 'ar' : 'article',
        notes: '',
        status: 'not-ready',
        created: timestamp,
        lastModified: timestamp,
        tenant,
        partitionKey: MPK.fromAttributes(tenant, m.productId),
    };
}
exports.fromModelInput = fromModelInput;
