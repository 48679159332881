import * as Preact from 'preact';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SxProps, Theme } from '@mui/system';
import * as Dimensions from './accordion/dimensions';

export interface Props {
    title: string;
    children?: JSX.Element;
    sx?: SxProps<Theme>;
}

export const Component = (p: Props) => {
    return (
        <Accordion disableGutters defaultExpanded={true}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                    backgroundColor: '#FFFFFF',
                    height: `${Dimensions.summaryHeight}px`,
                    paddingRight: `${Dimensions.itemPaddingX}px`,
                }}
            >
                <Typography sx={{ fontSize: '14px' }}>{p.title}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ ...p.sx, px: 0 }}>{p.children}</AccordionDetails>
        </Accordion>
    );
};
