"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isVariantAvailable = exports.getTypeForVariant = exports.getVariants = void 0;
function getVariants(space, productType = '') {
    return space.products
        .filter(product => productType === '' ? true : product.productType === productType)
        .reduce((variants, product) => [...variants, ...product.variants], []);
}
exports.getVariants = getVariants;
function getTypeForVariant(products, variant) {
    const product = products.find(product => {
        return !!product.variants.find(v => v.canonical_id === variant.canonical_id);
    });
    return product ? product.productType : undefined;
}
exports.getTypeForVariant = getTypeForVariant;
/** Returns true only in the event that the variant is not suspended and
 * is both in stock and not discontinued */
function isVariantAvailable(variant) {
    return (variant.availability !== 'suspended' &&
        (variant.availability === 'in-stock' || !variant.discontinued));
}
exports.isVariantAvailable = isVariantAvailable;
