import * as Preact from 'preact';
import Helmet from 'preact-helmet';

export interface Props {}

export const Component = (_: Props) => (
    <Helmet
        link={[
            {
                rel: 'stylesheet',
                href: 'https://fonts.googleapis.com/css?family=Inter:300,400,500,700&display=swap',
            },
        ]}
        meta={[
            { name: 'viewport', content: 'width=device-width, initial-scale=1' },
            { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        ]}
        style={[
            {
                type: 'text/css',
                cssText: 'body {height: 100vh; margin: 0; overflow: hidden}',
            },
        ]}
    />
);
