import { identity, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as Hooks from 'preact/hooks';
import { ImperativeInterface } from '../../arya';
import { useLoadingState } from './useLoadingState';
import * as LF from '../data/localFiles';
import { Pose } from '@digital-bridge/artisan-arya';

const unloadFile_ =
    (
        s: LF.LocalFileStore,
        aryaInterface: O.Option<ImperativeInterface>,
        setFileToNotLoaded: (f: LF.LocalFileReference, p: Pose) => void,
    ) =>
    (fileName: string) =>
        pipe(
            O.Do,
            O.bind('fileRef', () => LF.file(fileName)(s)),
            O.bind('arya', () => aryaInterface),
            O.bind('furnitureId', ({ fileRef }) => O.fromNullable(fileRef.furnitureId)),
            O.bind('pose', ({ furnitureId, arya }) => arya.furniturePose(furnitureId)),
            O.map(({ arya, furnitureId, fileRef, pose }) => {
                arya.removeFurniture(furnitureId);
                setFileToNotLoaded(fileRef, pose);
            }),
        );

const loadFile_ =
    (
        s: LF.LocalFileStore,
        aryaInterface: O.Option<ImperativeInterface>,
        setFileToLoaded: (f: LF.LocalFileReference, fId: string) => void,
    ) =>
    (fileName: string) =>
        pipe(
            O.Do,
            O.bind('arya', () => aryaInterface),
            O.bind('fileRef', () => LF.file(fileName)(s)),
            O.map(({ arya, fileRef }) => {
                fileRef.file
                    .arrayBuffer()
                    .then(ab => {
                        const pose = O.toUndefined(fileRef.pose);
                        return arya.addSimpleFurniture(fileName, ab, pose);
                    })
                    .then(furnitureId => {
                        setFileToLoaded(fileRef, furnitureId);
                    });
            }),
        );

/** Provide an interface for interacting with local files */
export const useLocalFiles = (aryaInterface: O.Option<ImperativeInterface>) => {
    // This hook is really just declaring a bunch of functions to help interact with the file
    // loading state at a higher level for the outside world.
    const {
        loading,
        loadedFiles: localFileStore,
        removeFile,
        setFileToLoaded,
        setFileToNotLoaded,
        queueFiles,
    } = useLoadingState(aryaInterface);

    const fileSizeBytes = (furnitureId: string) =>
        pipe(
            localFileStore,
            LF.fileSizeBytesByFurnitureId(furnitureId),
            O.match(() => 0, identity),
        );

    const fileName = (fId: string): string =>
        pipe(
            localFileStore,
            LF.fileNameByFurnitureId(fId),
            O.match(() => '', identity),
        );

    const fileNames = Hooks.useMemo(() => LF.fileNames(localFileStore), [localFileStore]);
    const files = Hooks.useMemo(
        () => fileNames.map(name => ({ name, loaded: localFileStore[name].loaded })),
        [localFileStore, fileNames],
    );

    const fileIsLoaded = Hooks.useMemo(
        () => (fileName: string) =>
            pipe(
                localFileStore,
                LF.loaded(fileName),
                O.match(() => false, identity),
            ),
        [localFileStore],
    );

    const furnitureIdFromFileName = Hooks.useMemo(
        () => (fileName: string) => pipe(localFileStore, LF.furnitureId(fileName)),
        [localFileStore],
    );

    /** Remove a file from the scene */
    const unloadFile = Hooks.useMemo(
        () => unloadFile_(localFileStore, aryaInterface, setFileToNotLoaded),
        [localFileStore, aryaInterface],
    );

    /** Add a file to the scene */
    const loadFile = Hooks.useMemo(
        () => loadFile_(localFileStore, aryaInterface, setFileToLoaded),
        [localFileStore, aryaInterface],
    );

    const deleteFile = Hooks.useMemo(
        () => (fileName: string) => {
            pipe(
                O.Do,
                O.bind('arya', () => aryaInterface),
                O.bind('fileRef', () => O.fromNullable(localFileStore[fileName])),
                O.map(({ arya, fileRef }) => {
                    if (fileRef.loaded && fileRef.furnitureId) {
                        arya.removeFurniture(fileRef.furnitureId);
                    }

                    removeFile(fileName);
                }),
            );
        },
        [aryaInterface, localFileStore],
    );

    const selectFile = Hooks.useMemo(
        () => (fileName: string) => {
            pipe(
                O.Do,
                O.bind('arya', () => aryaInterface),
                O.bind('furnitureId', () => furnitureIdFromFileName(fileName)),
                O.map(({ arya, furnitureId }) => {
                    arya.selectFurniture(furnitureId);
                }),
            );
        },
        [aryaInterface, localFileStore],
    );

    const isFileSelected = Hooks.useMemo(
        () => (fileName: string) =>
            pipe(
                O.Do,
                O.bind('arya', () => aryaInterface),
                O.bind('furnitureId', () => furnitureIdFromFileName(fileName)),
                O.map(({ arya, furnitureId }) => arya.selectedFurniture().includes(furnitureId)),
                O.match(
                    () => false,
                    v => v,
                ),
            ),
        [aryaInterface, localFileStore],
    );

    return {
        addFiles: queueFiles,
        /** Find the file size for a particular furniture ID */
        fileSizeBytes,
        fileName,
        fileNames,
        files,
        fileIsLoaded,
        loadFile,
        unloadFile,
        deleteFile,
        selectFile,
        isFileSelected,
        loading,
    };
};
