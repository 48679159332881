import * as Hooks from 'preact/hooks';
import * as O from 'fp-ts/Option';
import { ImperativeInterface } from '../arya';
import { pipe } from 'fp-ts/function';
import { AryaEvent, MaterialDetail } from '@digital-bridge/artisan-arya';
import { useLocalFiles } from './hooks/useLocalFiles';

export { useLocalFiles };

/** Control whether backfaces are currently hidden or not globally across Arya */
export const useBackfaceControls = (aryaInterface: O.Option<ImperativeInterface>) => {
    const [backfacesHidden, setBackfacesHidden] = Hooks.useState<boolean>(false);

    Hooks.useEffect(() => {
        pipe(
            aryaInterface,
            O.map(a => {
                if (backfacesHidden) {
                    a.hideBackfaces();
                } else {
                    a.showBackfaces();
                }
            }),
        );
    }, [aryaInterface, backfacesHidden]);

    return {
        hidden: backfacesHidden,
        toggle: () => setBackfacesHidden(value => !value),
    };
};

/** Visualise Origin */
export const useOriginVisualisation = (aryaInterface: O.Option<ImperativeInterface>) => {
    const [originVisualised, setOriginVisualised] = Hooks.useState(false);
    Hooks.useEffect(() => {
        pipe(
            aryaInterface,
            O.map(a => {
                if (originVisualised) {
                    a.visualiseOrigin();
                } else {
                    a.stopVisualiseOrigin();
                }
            }),
        );
    }, [aryaInterface, originVisualised]);

    return {
        toggle: () => setOriginVisualised(v => !v),
    };
};

export type SceneData = {
    // TODO pull under a single selectedFurniture key
    selectedFurniture: O.Option<string>;
    selectedFurnitureFilename: O.Option<string>;
    selectedFurnitureFilesize: number;
    selectedFurniturePolygonCount: O.Option<number>;
    selectedFurnitureMaterialDetails: O.Option<MaterialDetail[]>;
    selectedFurnitureBoundingBoxDimensions: O.Option<{
        width: number;
        height: number;
        depth: number;
    }>;
};

export const useSceneData = (
    aryaInterface: O.Option<ImperativeInterface>,
    {
        queryFilesize,
        queryFilename,
    }: {
        queryFilesize: (furnitureId: string) => number;
        queryFilename: (furnitureId: string) => string;
    },
): SceneData => {
    // TODO pull these out into smaller hooks
    const [selectedFurniture, setSelectedFurniture] = Hooks.useState<O.Option<string>>(O.none);
    const [selectedFurnitureFilesize, setSelectedFurnitureFilesize] = Hooks.useState<number>(0);
    const [selectedPolygonCount, setSelectedPolygonCount] = Hooks.useState<O.Option<number>>(
        O.none,
    );
    const [selectedMaterialDetails, setSelectedMaterialDetails] = Hooks.useState<
        O.Option<MaterialDetail[]>
    >(O.none);
    const [selectedName, setSelectedName] = Hooks.useState<O.Option<string>>(O.none);
    const [selectedDimensions, setSelectedDimensions] = Hooks.useState<
        O.Option<{ width: number; height: number; depth: number }>
    >(O.none);

    Hooks.useEffect(() => {
        const a = O.toNullable(aryaInterface);

        const handleFurnitureSelected = () => {
            const fs = a?.selectedFurniture() ?? [];
            setSelectedFurniture(fs.length > 0 ? O.some(fs[0]) : O.none);
        };

        const handleFurnitureDeselected = () => {
            setSelectedFurniture(O.none);
            setSelectedName(O.none);
            setSelectedFurnitureFilesize(0);
            setSelectedPolygonCount(O.none);
            setSelectedMaterialDetails(O.none);
        };

        a?.on(AryaEvent.FURNITURE_SELECTED, handleFurnitureSelected);
        a?.on(AryaEvent.FURNITURE_DESELECTED, handleFurnitureDeselected);

        return () => {
            a?.off(AryaEvent.FURNITURE_SELECTED, handleFurnitureSelected);
            a?.off(AryaEvent.FURNITURE_DESELECTED, handleFurnitureDeselected);
        };
    }, [aryaInterface]);

    Hooks.useEffect(() => {
        pipe(
            selectedFurniture,
            O.match(
                () => setSelectedFurnitureFilesize(0),
                furniture => {
                    setSelectedName(O.some(queryFilename(furniture)));
                    setSelectedFurnitureFilesize(queryFilesize(furniture));
                },
            ),
        );
    }, [selectedFurniture]);

    Hooks.useEffect(() => {
        pipe(
            aryaInterface,
            O.map(a => a.furnitureBoundingBox),
            O.ap(selectedFurniture),
            O.flatten,
            O.map(x => x.dimensions().value()),
            O.map(([x, y, z]) => ({ width: x, depth: z, height: y })),
            setSelectedDimensions,
        );
        pipe(
            aryaInterface,
            O.map(a => a.furniturePolygonCount),
            O.ap(selectedFurniture),
            setSelectedPolygonCount,
        );
        pipe(
            aryaInterface,
            O.map(a => a.furnitureMaterialDetails),
            O.ap(selectedFurniture),
            setSelectedMaterialDetails,
        );
    }, [selectedFurniture, aryaInterface]);

    return Hooks.useMemo(
        () => ({
            selectedFurniture,
            selectedFurnitureFilename: selectedName,
            selectedFurnitureFilesize,
            selectedFurniturePolygonCount: selectedPolygonCount,
            selectedFurnitureMaterialDetails: selectedMaterialDetails,
            selectedFurnitureBoundingBoxDimensions: selectedDimensions,
        }),
        [
            selectedFurniture,
            selectedFurnitureFilesize,
            selectedPolygonCount,
            selectedMaterialDetails,
            selectedName,
            selectedDimensions,
        ],
    );
};
