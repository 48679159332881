import * as Preact from 'preact';
import * as Hooks from 'preact/hooks';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import * as Dimensions from '../dimensions';

export interface Props {
    check: string;
    checkedList: string[];
    setCheckedList: Hooks.StateUpdater<string[]>;
}

export const Component = (p: Props) => {
    const onToggleClick = (check: string) => {
        if (p.checkedList.includes(check)) {
            p.setCheckedList(p.checkedList.filter(item => item !== check));
        } else {
            p.setCheckedList([...p.checkedList, check]);
        }
    };

    return (
        <ListItemButton
            onClick={() => onToggleClick(p.check)}
            disableGutters
            dense
            sx={{
                height: `${Dimensions.itemHeight}px`,
                padding: Dimensions.itemPadding,
            }}
        >
            <ListItemText primaryTypographyProps={{ variant: 'body1' }}>{p.check}</ListItemText>
            <Checkbox
                checked={p.checkedList.includes(p.check)}
                sx={{ padding: 0, '& .MuiSvgIcon-root': { fontSize: 22 } }}
            />
        </ListItemButton>
    );
};
