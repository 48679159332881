import * as TE from 'fp-ts/TaskEither';
import * as E from 'fp-ts/Either';
import * as O from 'fp-ts/Option';
import * as Err from '@fixtuur/error-context';
import Papa from 'papaparse';
import { pipe } from 'fp-ts/lib/function';

export interface CsvRowInput {
    'Product Identifier (e.g. SKU, EAN)': string;
    Articles: string;
    'Product Category': string;
}

//TODO: handle Product IDs that are already in the tenants list

export const parseCsv = (f: File): TE.TaskEither<Err.ErrorContext<string>, CsvRowInput[]> => {
    return () =>
        new Promise((resolve, reject) => {
            Papa.parse<CsvRowInput>(f, {
                header: true,
                complete: results => resolve(E.right(results.data)),
                error: err => resolve(E.left(Err.fromUnknown(err))),
            });
        });
};

export const normalise = (row: CsvRowInput): CsvRowInput => {
    if (!row['Articles']) {
        if (row['Product Category']) {
            row['Articles'] = row['Product Category'];
        }
    }
    return row;
};

export const productId = (input: CsvRowInput): O.Option<string> => {
    return pipe(
        input['Product Identifier (e.g. SKU, EAN)'].trim(),
        O.fromNullable,
        O.filter(productId => productId !== ''),
    );
};

export const articles = (input: CsvRowInput): O.Option<string> => {
    return pipe(
        input['Articles'],
        O.fromNullable,
        O.filter(article => article !== ''),
    );
};
