import { Pose } from '@digital-bridge/artisan-arya';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as Hooks from 'preact/hooks';
import { ImperativeInterface } from '../../arya';
import { LocalFileReference, LocalFileStore } from '../data/localFiles';

/** Load a file and update the file store to include this file */
const loadLocalFileInitially =
    (arya: ImperativeInterface) => (file: File, updateFiles: Hooks.StateUpdater<LocalFileStore>) =>
        file.arrayBuffer().then(buffer => {
            arya.addSimpleFurniture(file.name, buffer).then(furnitureId =>
                updateFiles(store => ({
                    ...store,
                    [file.name]: { file, furnitureId, loaded: true, pose: O.none },
                })),
            );
        });

/** Introduce a queue and internal state for managing loaded files */
export const useLoadingState = (aryaInterface: O.Option<ImperativeInterface>) => {
    const [loadingFiles, setLoadingFiles] = Hooks.useState<File[]>([]);
    const [loadedFiles, setLoadedFiles] = Hooks.useState<LocalFileStore>({});

    const loading = loadingFiles.length > 0;

    Hooks.useEffect(() => {
        if (loading) {
            const file = loadingFiles[0];

            if (loadedFiles[file.name] !== undefined) {
                alert(
                    'The application does not currently support loading multiple files with the same name',
                );
                setLoadingFiles(loading => loading.filter(l => l.name !== file.name));
            } else {
                pipe(
                    aryaInterface,
                    O.map(loadLocalFileInitially),
                    O.map(f => {
                        f(file, setLoadedFiles).then(() => {
                            setLoadingFiles(loading => loading.filter(l => l.name !== file.name));
                        });
                    }),
                );
            }
        }
    }, [aryaInterface, loadingFiles, loadedFiles]);

    const setFileToNotLoaded = (fileRef: LocalFileReference, pose: Pose) => {
        setLoadedFiles(state => ({
            ...state,
            [fileRef.file.name]: {
                ...fileRef,
                furnitureId: null,
                loaded: false,
                pose: O.some(pose),
            },
        }));
    };

    const setFileToLoaded = (fileRef: LocalFileReference, furnitureId: string) => {
        setLoadedFiles(state => ({
            ...state,
            [fileRef.file.name]: { ...fileRef, furnitureId, loaded: true },
        }));
    };

    const removeFile = (fileName: string) => {
        setLoadedFiles(state => {
            delete state[fileName];
            return { ...state };
        });
    };

    const queueFiles = (files: File[]) => setLoadingFiles(fs => fs.concat(files));

    return {
        loading,
        loadedFiles,
        setFileToNotLoaded,
        setFileToLoaded,
        removeFile,
        queueFiles,
    };
};
