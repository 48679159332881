/**
 * Is the current environment a deployed `dev` environment?
 *
 * NOTE that this does not include localhost.
 */
export const isDev = () => {
    const url = new URL(window.location.href);
    /** should allow for any host with '.dev.dana.' included */
    const regexp = /.*\.dev\.dana\..*/;

    return regexp.test(url.host);
};

export const isStg = () => {
    const url = new URL(window.location.href);
    /** should allow for any host with '.dev.dana.' included */
    const regexp = /.*\.stg\.dana\..*/;

    return regexp.test(url.host);
};

export const isLocalhost = () => {
    const url = new URL(window.location.href);
    return url.toString().startsWith('http://localhost');
};

export const baseUrl = () => (isDev() ? new URL(window.location.href).pathname.split('/')[1] : '');

/**
 * Return a path which takes into account the Base path of the current page. This enables the links
 * to work in dev stg or prd environments.
 */
export const pathUrl = (path: string) => {
    const base = baseUrl();
    const startsWithSlash = base !== '';
    const newPath = `${startsWithSlash ? '/' : ''}${base}${path}`;
    return newPath;
};

export const stage = () => (isLocalhost() || isDev() ? 'dev' : isStg() ? 'stg' : 'prd');
export const tenant = 'moda';

export const serviceUrls = {
    toastie: new URL(`api/v1/${tenant}`, `https://eu.${stage()}.toastie.fixtuur.com/`),
};
