"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toLocaleString = void 0;
const fallback = (price) => price.toFixed(2).toString();
function toLocaleString(price, locale, currency) {
    // fallback for initial state
    if (!locale || !currency) {
        return fallback(price);
    }
    // this could fail if the locale or currency is invalid
    try {
        return price.toLocaleString(locale, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            currency: currency,
            style: 'currency',
            currencyDisplay: 'narrowSymbol',
        });
    }
    catch (err) {
        if (!(err instanceof RangeError)) {
            throw err;
        }
        console.warn(`warning: invalid localisation configuration (${err.message})`);
        return fallback(price);
    }
}
exports.toLocaleString = toLocaleString;
