"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormRequestData = exports.JSONRequestData = exports.Request = void 0;
class Request {
    constructor(endpoint = '', method = 'GET', data) {
        this.endpoint = endpoint;
        this.method = method;
        this.data = data;
    }
}
exports.Request = Request;
class JSONRequestData {
    constructor(data) {
        this.data = data;
    }
    get requestType() {
        return 'JSON';
    }
}
exports.JSONRequestData = JSONRequestData;
class FormRequestData {
    constructor(data) {
        this.data = data;
    }
    get requestType() {
        return 'FORMDATA';
    }
}
exports.FormRequestData = FormRequestData;
