import * as Preact from 'preact';
import * as Hooks from 'preact/hooks';
import { forwardRef } from 'preact/compat';
import styled from '@mui/system/styled';
import { useArya } from './arya/hooks/useArya';
import * as O from 'fp-ts/Option';
import * as II from './arya/hooks/imperativeInterface';

export type ImperativeInterface = II.ImperativeInterface;

const FullSizeCanvas = styled('canvas')(() => ({
    width: '100%',
    height: '100%',
}));

const CursorInteractiveCanvas = styled(FullSizeCanvas)(() => ({
    '&.cursor-default': {
        cursor: 'default',
    },
    '&.cursor-pointer': {
        cursor: 'url(assets/cursors/pointer.svg) 10 10, url(assets/cursors/pointer.png) 10 10, pointer',
    },
    '&.cursor-grab': {
        cursor: 'url(assets/cursors/grab.svg) 10 10, url(assets/cursors/grab.png) 10 10, grab',
    },
    '&.cursor-grabbing': {
        cursor: 'url(assets/cursors/grabbing.svg) 10 10, url(assets/cursors/grabbing.png) 10 10, grabbing',
    },
    '&.cursor-move': {
        cursor: 'move',
    },
    '&.cursor-rotate': {
        cursor: 'url(assets/cursors/rotate.svg) 10 10, url(assets/cursors/rotate.png) 10 10, ew-resize',
    },
}));

export interface Props {}

const Component_ = (_: Props, ref: Preact.Ref<O.Option<ImperativeInterface>>) => {
    const canvas3d = Hooks.useRef<any>();
    const canvas2d = Hooks.useRef<any>();
    const arya = useArya(canvas3d, canvas2d);

    Hooks.useImperativeHandle(ref, () => arya, [arya]);
    return (
        <>
            <CursorInteractiveCanvas ref={canvas3d}></CursorInteractiveCanvas>
            <FullSizeCanvas ref={canvas2d} sx={{ display: 'none' }}></FullSizeCanvas>
        </>
    );
};

export const Component = forwardRef<O.Option<ImperativeInterface>, Props>(Component_);
