"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModelDerivedData = exports.ModelIdData = exports.ModelTenant = exports.ModelFiles = exports.File = exports.ArticleType = exports.Status = exports.Datetime = exports.IOError = void 0;
const t = __importStar(require("io-ts"));
var IOError;
(function (IOError) {
    IOError["UnableToDecode"] = "Unable to decode io-ts type";
})(IOError = exports.IOError || (exports.IOError = {}));
/** Building blocks for creating the necessary model types */
// TODO: Consider a custom type enforcing an ISO8601 string, possibly using date-fns `parseISO`
// function. See: https://github.com/gcanti/io-ts/blob/master/index.md#custom-types
exports.Datetime = t.string;
exports.Status = t.keyof({ 'not-ready': null, ready: null, approved: null, rejected: null });
exports.ArticleType = t.union([t.string, t.literal('ar-model')]);
/** Files which have not yet been created have null attributes */
const nullableString = t.union([t.string, t.null]);
const nullableDate = t.union([exports.Datetime, t.null]);
exports.File = t.type({
    location: nullableString,
    created: nullableDate,
    lastModified: nullableDate,
});
/** Model files attribute */
exports.ModelFiles = t.type({
    // if these are modelled as `Records` as may feel natural, then you
    // can end up with a `model` with a `json` field, or an `attachments` with a `glb` field.
    // This isn't desirable. As it's possible for a file to have some files but not others,
    // then a partial type seems the best approach.
    files: t.partial({
        model: t.partial({ glb: exports.File, blend: exports.File, max: exports.File }),
        attachments: t.partial({ json: exports.File, blend: exports.File }),
    }),
});
/** Model tenant attribute */
exports.ModelTenant = t.type({ tenant: t.string });
/** Model attributes which uniquely identify a model for a given tenant */
exports.ModelIdData = t.type({
    productId: t.string,
    articleType: exports.ArticleType,
});
/** Attributes of a model item that are generated on creation
 * (some of which may be modifiable manually after model item creation) */
exports.ModelDerivedData = t.type({
    notes: t.string,
    status: exports.Status,
    /** TODO: could this be expressed as a tagged union instead for increased type safety? */
    kind: t.keyof({
        article: null,
        ar: null,
    }),
    created: exports.Datetime,
    lastModified: exports.Datetime,
});
