import { SceneData, SceneItemControlList, StyledSwitch } from '@dora/components';
import Box from '@mui/material/Box';
import { pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/Option';
import * as Preact from 'preact';
import * as Hooks from 'preact/hooks';
import * as AddModelButton from './addModelButton';
import {
    useBackfaceControls,
    useLocalFiles,
    useOriginVisualisation,
    useSceneData,
} from './modelTestPage/hooks';
import * as AppBar from './appBar';
import { appBarHeight } from './appBar/dimensions';
import * as Arya from './arya';
import * as Maybe from './maybe';
import * as SidePanel from './sidePanel';
import * as Accordion from './sidePanel/accordion';
import * as ChecklistPanel from './sidePanel/accordion/checklistPanel';
import * as ControlPanel from './sidePanel/accordion/controlPanel';
import { FormControl, Stack, Typography } from '@mui/material';

export interface Props {}

export const Component: Preact.FunctionComponent<Props> = (_: Props) => {
    const [aryaInterface, setAryaInterface] = Hooks.useState<O.Option<Arya.ImperativeInterface>>(
        O.none,
    );
    const backfaceControls = useBackfaceControls(aryaInterface);
    const originVisualisation = useOriginVisualisation(aryaInterface);
    const localFiles = useLocalFiles(aryaInterface);
    const sceneData = useSceneData(aryaInterface, {
        queryFilesize: localFiles.fileSizeBytes,
        queryFilename: localFiles.fileName,
    });

    // collect the data necessary together into a single component
    const displaySceneData = pipe(
        O.Do,
        O.bind('name', () => sceneData.selectedFurnitureFilename),
        O.bind('polys', () => sceneData.selectedFurniturePolygonCount),
        O.bind('bounds', () => sceneData.selectedFurnitureBoundingBoxDimensions),
        O.bind('materials', () => sceneData.selectedFurnitureMaterialDetails),
        O.map(({ polys, materials, name, bounds }) => (
            <Box sx={{ position: 'absolute', left: 20, top: 60 }}>
                <SceneData.Component
                    file={{
                        name,
                        sizeBytes: sceneData.selectedFurnitureFilesize,
                    }}
                    model={{
                        polygons: polys,
                        materialDetails: materials,
                        boundingBoxDimensions: bounds,
                    }}
                />
            </Box>
        )),
    );

    const [lightingMode, setLightingMode] = Hooks.useState<'indoor' | 'outdoor'>('outdoor');

    Hooks.useEffect(() => {
        updateLightingAndEnvironment(lightingMode);
    }, [lightingMode]);

    const updateLightingAndEnvironment = (lightingMode: 'indoor' | 'outdoor') => {
        const lightSettings =
            lightingMode === 'outdoor'
                ? {
                      lightingMode: 'outdoor',
                      lightEnvMapFilename: 'lightEnvMap.hdr',
                      skyEnvMapFilename: 'skyEnvMap.png',
                  }
                : {
                      lightingMode: 'indoor',
                      lightEnvMapFilename: 'neutral.hdr',
                      skyEnvMapFilename: undefined,
                  };

        pipe(
            aryaInterface,
            O.map(a => {
                a.updateLighting(
                    lightSettings.lightingMode as 'indoor' | 'outdoor',
                    'assets/',
                    lightSettings.lightEnvMapFilename,
                    lightSettings.skyEnvMapFilename,
                );
                a.setRoomGeometryVisibility(lightingMode === 'outdoor');
            }),
        );
    };

    return (
        <div style={{ height: '100%' }}>
            <AppBar.Component />
            <Box
                sx={{
                    height: `calc(100% - ${appBarHeight}px)`,
                    display: 'grid',
                    gridTemplate: `100% / 71% 29%`,
                }}
            >
                <Box sx={{ position: 'relative' }}>
                    <AddModelButton.Component
                        disabled={localFiles.loading}
                        onFilesReady={localFiles.addFiles}
                        sx={{ position: 'fixed', bottom: 10, right: '30%', height: '50px' }}
                    />

                    <Maybe.Component>{displaySceneData}</Maybe.Component>

                    <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ position: 'fixed', top: '84px', left: '24px' }}
                    >
                        <Typography fontSize={14} fontWeight="bold">
                            Indoor
                        </Typography>
                        <FormControl>
                            <StyledSwitch.Component
                                sx={{ m: 1 }}
                                defaultChecked
                                checked={lightingMode === 'outdoor'}
                                onChange={(e, checked) =>
                                    setLightingMode(checked ? 'outdoor' : 'indoor')
                                }
                            />
                        </FormControl>
                        <Typography fontSize={14} fontWeight="bold">
                            Outdoor
                        </Typography>
                    </Stack>

                    <Arya.Component
                        ref={(instance: O.Option<Arya.ImperativeInterface> | null) => {
                            if (instance !== null) {
                                setAryaInterface(instance);
                            }
                        }}
                    ></Arya.Component>
                </Box>
                <SidePanel.Component>
                    <Accordion.Component title="Models" sx={{ padding: 0 }}>
                        <SceneItemControlList.Component
                            items={localFiles.files.map(({ name, loaded }) => ({
                                name,
                                visible: loaded,
                                selected: localFiles.isFileSelected(name),
                            }))}
                            onView={fileName => {
                                if (localFiles.fileIsLoaded(fileName)) {
                                    localFiles.unloadFile(fileName);
                                } else {
                                    localFiles.loadFile(fileName);
                                }
                            }}
                            onDelete={localFiles.deleteFile}
                            onSelect={localFiles.selectFile}
                        />
                    </Accordion.Component>
                    <Accordion.Component
                        title="Controls"
                        children={
                            <ControlPanel.Component
                                onViewOriginClick={() => originVisualisation.toggle()}
                                onViewBackfaceClick={() => backfaceControls.toggle()}
                            />
                        }
                    />
                    <Accordion.Component title="Checks" children={<ChecklistPanel.Component />} />
                </SidePanel.Component>
            </Box>
        </div>
    );
};
Component.displayName = 'ModelTestPage';
