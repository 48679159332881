"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DesignsJSON = exports.ProductTypes = exports.CustomisationTypes = exports.CategoryTypes = void 0;
var CategoryTypes;
(function (CategoryTypes) {
    CategoryTypes["NotSpecified"] = "notSpecified";
    CategoryTypes["Basin"] = "basin";
    CategoryTypes["Bath"] = "bath";
    CategoryTypes["Shower"] = "shower";
    CategoryTypes["Storage"] = "storage";
    CategoryTypes["Toilet"] = "toilet";
    CategoryTypes["Wall"] = "wall";
    CategoryTypes["Floor"] = "floor";
    CategoryTypes["Fixture"] = "fixture";
})(CategoryTypes = exports.CategoryTypes || (exports.CategoryTypes = {}));
var CustomisationTypes;
(function (CustomisationTypes) {
    CustomisationTypes["Basin"] = "basin";
    CustomisationTypes["Bath"] = "bath";
    CustomisationTypes["Shower"] = "shower";
    CustomisationTypes["Storage"] = "storage";
    CustomisationTypes["Toilet"] = "toilet";
    CustomisationTypes["Tile"] = "tile";
    CustomisationTypes["Paint"] = "paint";
    // placeholder types for feature placement products
    CustomisationTypes["Door"] = "door";
    CustomisationTypes["SoilPipe"] = "soilPipe";
    CustomisationTypes["Window"] = "window";
})(CustomisationTypes = exports.CustomisationTypes || (exports.CustomisationTypes = {}));
/**
 * Group types by the customisation space they appear in.
 * Prefix types by the name of the customisation space they appear in.
 */
var ProductTypes;
(function (ProductTypes) {
    ProductTypes["NotSpecified"] = "notSpecified";
    // placeholder types for feature placement products
    ProductTypes["Door"] = "door";
    ProductTypes["Window"] = "window";
    ProductTypes["SoilPipe"] = "soilPipe";
    // toilet products
    ProductTypes["Toilet"] = "toilet";
    ProductTypes["ToiletSeat"] = "toiletSeat";
    // bath products
    ProductTypes["BathBathTub"] = "bathBathTub";
    ProductTypes["BathSidePanel"] = "bathSidePanel";
    ProductTypes["BathEndPanel"] = "bathEndPanel";
    ProductTypes["BathTap"] = "bathTap";
    ProductTypes["BathWaste"] = "bathWaste";
    // shower products
    ProductTypes["ShowerTray"] = "showerTray";
    ProductTypes["ShowerSidePanel"] = "showerSidePanel";
    ProductTypes["ShowerDoor"] = "showerDoor";
    ProductTypes["ShowerControl"] = "showerControl";
    ProductTypes["ShowerEnclosure"] = "showerEnclosure";
    ProductTypes["ShowerWaste"] = "showerWaste";
    // storage products
    ProductTypes["StorageCabinet"] = "storageCabinet";
    ProductTypes["StorageWorktop"] = "storageWorktop";
    // basin products
    ProductTypes["IntegratedBasin"] = "integratedBasin";
    ProductTypes["CountertopBasin"] = "countertopBasin";
    ProductTypes["PedestalBasin"] = "pedestalBasin";
    // tap products
    ProductTypes["BasinMountedTap"] = "basinMountedTap";
    ProductTypes["BasinFreestandingTap"] = "basinFreestandingTap";
    // waste products
    ProductTypes["BasinSlottedWaste"] = "basinSlottedWaste";
    ProductTypes["BasinUnslottedWaste"] = "basinUnslottedWaste";
    // covering products
    ProductTypes["WallTile"] = "wallTile";
    ProductTypes["FloorTile"] = "floorTile";
    ProductTypes["WallFloorTile"] = "wallFloorTile";
    ProductTypes["Paint"] = "paint";
})(ProductTypes = exports.ProductTypes || (exports.ProductTypes = {}));
// --- Designs Microservice ---//
var DesignsJSON;
(function (DesignsJSON) {
    let Product;
    (function (Product) {
        Product["bathroom"] = "bathroom";
        Product["kitchen"] = "kitchen";
    })(Product = DesignsJSON.Product || (DesignsJSON.Product = {}));
    // The flavour of the high-quality rendering algorithm. Currently, the only supported flavour is chroma.
    let Flavour;
    (function (Flavour) {
        Flavour["chroma"] = "chroma";
    })(Flavour = DesignsJSON.Flavour || (DesignsJSON.Flavour = {}));
    let Quality;
    (function (Quality) {
        Quality["good"] = "good";
        Quality["better"] = "better";
        Quality["best"] = "best";
    })(Quality = DesignsJSON.Quality || (DesignsJSON.Quality = {}));
    let Status;
    (function (Status) {
        /** Render has not yet started  */
        Status["pending"] = "pending";
        /** Render has been queued */
        Status["queued"] = "queued";
        /**
         * Render is currently in progress
         * @deprecated
         * */
        Status["rendering"] = "rendering";
        /**
         * Render is currently in progress
         * */
        Status["processing"] = "processing";
        /**
         * Rendering has finished.
         * @deprecated
         * */
        Status["rendered"] = "rendered";
        /**
         * Render has completed
         */
        Status["complete"] = "complete";
        /**
         * Render has failed.
         */
        Status["failed"] = "failed";
        /**
         * Render has been cancelled.
         * this is not currently a real api status, it's used to signify tasks which have
         * been deleted from the api and should no longer be displayed or polled.
         */
        Status["cancelled"] = "cancelled";
    })(Status = DesignsJSON.Status || (DesignsJSON.Status = {}));
})(DesignsJSON = exports.DesignsJSON || (exports.DesignsJSON = {}));
