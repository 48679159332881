import {
    AryaAdaptor,
    AryaEvent,
    AryaFurniture,
    MaterialDetail,
    Pose,
} from '@digital-bridge/artisan-arya';
import { constant, pipe } from 'fp-ts/lib/function';
import * as Hooks from 'preact/hooks';
import * as O from 'fp-ts/Option';
import * as Arya from '../data/arya';
import * as R from 'fp-ts/Reader';
import { IAxisAlignedBoundingBox } from '@digital-bridge/fine';

export type ImperativeInterface = {
    /** Returns the Furniture ID associated with the simple furniture created */
    addSimpleFurniture: (name: string, glb: ArrayBuffer, pose?: Pose) => Promise<string>;
    furniture: () => Array<AryaFurniture>;

    furniturePolygonCount: (furnitureId: string) => number;
    furnitureMaterialDetails: (furnitureId: string) => MaterialDetail[];
    furnitureBoundingBox: (furnitureId: string) => O.Option<IAxisAlignedBoundingBox>;
    furniturePose: (furnitureId: string) => O.Option<Pose>;

    removeFurniture: (furnitureId: string) => void;
    selectFurniture: (furnitureId: string) => void;

    /** Hide all model's backfaces - should help to determine whether there's problems with mesh
     * normals */
    hideBackfaces: () => void;
    /** Show all model's backfaces (default view) */
    showBackfaces: () => void;

    visualiseOrigin: () => void;
    stopVisualiseOrigin: () => void;

    selectedFurniture: () => Array<string>;
    updateLighting: (
        lighting: 'indoor' | 'outdoor',
        path: string,
        lightEnvMapFilename: string,
        skyEnvMapFilename?: string,
    ) => void;
    setRoomGeometryVisibility: (visible: boolean) => void;

    /** Subscribe to an Arya event */
    on: (event: AryaEvent, handler: Function) => void;
    /** Unsubscribe from an Arya event */
    off: (event: AryaEvent, handler: Function) => void;
};

/**
 * READER EXPLANATION INCOMING:
 *
 * Using a Reader here to avoid having to manually 'thread' arya into all of these functions. A
 * Reader allows you to compose multiple functions with a common input, allowing you to pass the
 * input only once.
 *
 * By using the bind function here, we take a series of functions with the same input and produce an
 * object with keys of these names, where the values are that of the values returned when the
 * function is called with the common input.
 *
 * The data-type of a Reader<A, B> is a function of A => B.
 *
 * The Reader monad gives us ways to compose functions in the same way Array lets us
 * compose Arrays, or Option lets us compose Options. Remember that a monad is a collection of
 * operations over a data-type, and is separate from the data-type itself.
 */
const rImperativeInterface: R.Reader<AryaAdaptor, ImperativeInterface> = pipe(
    R.Do,
    R.bind('addSimpleFurniture', constant(Arya.addSimpleFurnitureFromGlbAsset)),
    R.bind('furniture', constant(Arya.furniture)),
    R.bind('furniturePolygonCount', constant(Arya.furniturePolygonCount)),
    R.bind('furnitureMaterialDetails', constant(Arya.furnitureMaterialDetails)),
    R.bind('furnitureBoundingBox', constant(Arya.furnitureBoundingBox)),
    R.bind('furniturePose', constant(Arya.furniturePose)),
    R.bind('removeFurniture', constant(Arya.removeFurniture)),
    R.bind('selectFurniture', constant(Arya.selectFurniture)),
    R.bind('hideBackfaces', constant(Arya.hideBackfaces)),
    R.bind('showBackfaces', constant(Arya.showBackfaces)),
    R.bind('visualiseOrigin', constant(Arya.visualiseOrigin)),
    R.bind('stopVisualiseOrigin', constant(Arya.stopVisualiseOrigin)),
    R.bind('selectedFurniture', constant(Arya.selectedFurniture)),
    R.bind('updateLighting', constant(Arya.updateEnvironment)),
    R.bind('setRoomGeometryVisibility', constant(Arya.setRoomGeometryVisibility)),
    R.bind('on', constant(Arya.on)),
    R.bind('off', constant(Arya.off)),
);

export const useImperativeInterface = (arya: AryaAdaptor | null): O.Option<ImperativeInterface> => {
    return Hooks.useMemo(() => {
        // Here is where we do the threading arya into the Reader, which returns an ImperativeInterface
        // Again, a Reader<AryaAdaptor, ImpertativeInterface> is a function AryaAdaptor => ImperativeInterface
        return pipe(arya, O.fromNullable, O.map(rImperativeInterface));
    }, [arya]);
};
