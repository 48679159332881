"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromUnknown = exports.Data = exports.FileExtension = exports.FileKind = void 0;
const i = __importStar(require("io-ts"));
const function_1 = require("fp-ts/function");
const E = __importStar(require("fp-ts/Either"));
const Err = __importStar(require("@fixtuur/error-context"));
const io_ts_reporters_1 = require("io-ts-reporters");
const base_1 = require("../base");
/** Types related to items stored in s3 for toastie */
exports.FileKind = i.keyof({ attachments: null, model: null });
exports.FileExtension = i.keyof({ json: null, glb: null, max: null, blend: null });
/** Expected metadata stored alongside a model file in s3 */
exports.Data = i.type({
    // NOTE: all keys must be lowercase (turns out s3 url metadata doesn't preserve case)
    tenant: i.string,
    productid: i.string,
    articletype: i.string,
    filekind: exports.FileKind,
    fileextension: exports.FileExtension,
});
const fromUnknown = (item) => {
    return (0, function_1.pipe)(item, exports.Data.decode, E.mapLeft(io_ts_reporters_1.formatValidationErrors), E.mapLeft(errorList => Err.construct(base_1.IOError.UnableToDecode, {
        errors: JSON.stringify(errorList),
    })));
};
exports.fromUnknown = fromUnknown;
