import * as Preact from 'preact';
import * as Hooks from 'preact/hooks';
import { useProductList, ProductColumns } from './productPageWrapper/hooks';
import { GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { ProductPage } from '@dora/components';
import { useProductModels } from './singleProductWrapper/hooks';
import { Model, Api } from '@dora/backpack';
import { serviceUrls } from '../environment';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/TaskEither';
import * as E from 'fp-ts/Either';
import * as T from 'fp-ts/Task';
import { tenant } from '../environment';
import { parseCsv } from './productPageWrapper/data/csvRowInput';
import { fromCsvRowInputs } from './productPageWrapper/data/modelId';
import * as Err from '@fixtuur/error-context';

export interface Props {
    openModelChecker: () => void;
}

enum OnUploadCsvError {
    ParseError = 'Unable to parse CSV file',
    ServerError = 'Unable to upload model data',
    ProcessingError = 'Unable to process CSV file. Potential missing data',
}

export const Component: Preact.FunctionComponent<Props> = (p: Props) => {
    const [showAlert, setShowAlert] = Hooks.useState<boolean>(false);

    const { productRows: rows, refresh: refreshList } = useProductList();

    const columns: GridColDef[] = ProductColumns.map(column => {
        return { field: column.field, headerName: column.headerName, minWidth: 150, flex: 1 };
    });

    const onFileDownload = (fileLocation: string) => {
        location.href = fileLocation;
    }; // when a file is downloaded to the local machine

    const refresh = () => {
        refreshList();
        refreshDrawer();
        showAlert && setShowAlert(false);
    };

    const onFileUpload = (file: Model.FileChangeSet) => {
        pipe(
            {
                productId: file.model.productId,
                articleType: file.model.articleType,
                body: { kind: file.kind, extension: file.extension },
            },
            Api.PatchModelFiles.request(new URL(serviceUrls.toastie)),
            TE.chain(response =>
                Api.PutFile.request(response.uploadUrl)({
                    file: file.file,
                    fileName: `${tenant}-${file.model.productId}-${file.model.articleType}-${file.kind}.${file.extension}`,
                }),
            ),
            TE.fold(
                errorContext => T.of(console.error('File upload failed', { errorContext })),
                () => T.of(setShowAlert(true)),
            ),
        )();
    };

    // when a model has passed in the model checker
    const onModelStatusUpdate = (model: Model.Model, status: Model.Status) => {
        pipe(
            {
                productId: model.productId,
                articleType: model.articleType,
                body: {
                    status,
                },
            },
            Api.PatchModel.request(new URL(serviceUrls.toastie)),
            TE.fold(
                errorContext => T.of(console.error('Model status update failed', { errorContext })),
                () => T.of(setShowAlert(true)),
            ),
        )();
    };

    const onCheckModel = () => console.log('check');

    const onAddModel = (productId: string, articleType: string): Promise<boolean> => {
        return pipe(
            { body: [{ productId, articleType }] },
            Api.PostModels.request(new URL(serviceUrls.toastie)),
            TE.fold(
                (errorContext): T.Task<boolean> => {
                    console.error('Add model failed', { errorContext });
                    return T.of(false);
                },
                succeeded => {
                    succeeded && setShowAlert(true);
                    return T.of(succeeded);
                },
            ),
        )();
    };

    const onDeleteModel = (productId: string, articleType: string): Promise<boolean> => {
        return pipe(
            { productId, articleType },
            Api.DeleteModel.request(new URL(serviceUrls.toastie)),
            TE.fold(
                (errorContext): T.Task<boolean> => {
                    console.error('Delete model failed', { errorContext });
                    return T.of(false);
                },
                succeeded => {
                    succeeded && setShowAlert(true);
                    return T.of(succeeded);
                },
            ),
        )();
    };

    const [productId, setProductId] = Hooks.useState<string | null>(null);

    const { productModels: models, refresh: refreshDrawer } = useProductModels(
        productId ?? undefined,
    );

    const articleModels = models.filter(model => model.kind === 'article');
    const arModel = models.find(model => model.kind === 'ar');

    const onSelectRow = (productId: string) => {
        setProductId(productId);
    };

    const onCloseDrawer = () => {
        setProductId(null);
    };

    const onCloseAlert = () => {
        setShowAlert(false);
    };

    const onUploadCsv = (f: File): Promise<boolean> => {
        return pipe(
            parseCsv(f),
            TE.mapLeft(Err.swap(OnUploadCsvError.ParseError)),
            /* TE.fromEither failed to type correctly so it has been manually converted 
               TODO: extract to its own function */
            TE.chain(csv =>
                pipe(
                    csv,
                    fromCsvRowInputs,
                    E.fold(
                        e => TE.left(e),
                        x => TE.right(x),
                    ),
                    TE.mapLeft(Err.swap(OnUploadCsvError.ProcessingError)),
                ),
            ),
            TE.map(body => ({ body })),
            TE.chain(data =>
                pipe(
                    data,
                    Api.PostModels.request(new URL(serviceUrls.toastie)),
                    TE.mapLeft(Err.swap(OnUploadCsvError.ServerError)),
                ),
            ),
            TE.fold(
                (errorContext): T.Task<boolean> => {
                    console.error('post models failed', { errorContext });
                    return T.of(false);
                },
                succeeded => {
                    //TODO: when should the alert appear? after the modal has been closed?
                    succeeded && setShowAlert(true);
                    return T.of(succeeded);
                },
            ),
        )();
    };

    const tenantTitle = tenant.charAt(0).toUpperCase() + tenant.slice(1);

    return (
        <ProductPage.Component
            tenant={tenantTitle}
            openModelChecker={p.openModelChecker}
            refresh={refresh}
            columns={columns}
            rows={rows}
            onSelectRow={onSelectRow}
            selectedProductId={productId}
            onModelStatusUpdate={onModelStatusUpdate}
            onCheckModel={onCheckModel}
            onAddModel={onAddModel}
            onDeleteModel={onDeleteModel}
            onCloseDrawer={onCloseDrawer}
            articleModels={articleModels}
            arModel={arModel}
            onFileUpload={onFileUpload}
            onFileDownload={onFileDownload}
            onCloseAlert={onCloseAlert}
            alertAction={refresh}
            showAlert={showAlert}
            onUploadCsv={onUploadCsv}
        />
    );
};
Component.displayName = 'ProductPageWrapper';
