"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.arrayFromUnknown = exports.fromUnknown = exports.arrayFromUnknownDynamoDbModels = exports.fromUnknownDynamoDbModel = exports.Data = void 0;
const t = __importStar(require("io-ts"));
const Err = __importStar(require("@fixtuur/error-context"));
const base_1 = require("../base");
const function_1 = require("fp-ts/function");
const E = __importStar(require("fp-ts/Either"));
const Arr = __importStar(require("fp-ts/Array"));
const dynamoDb_1 = require("../dynamoDb");
const io_ts_reporters_1 = require("io-ts-reporters");
/** A model summary as returned by this service, excludes files */
exports.Data = t.intersection([t.exact(base_1.ModelIdData), t.exact(base_1.ModelDerivedData)]);
const fromUnknownDynamoDbModel = (item) => {
    return (0, function_1.pipe)(item, dynamoDb_1.ModelSummary.Data.decode, E.chain(item => exports.Data.decode({
        productId: item.productId,
        articleType: item.articleType,
        kind: item.kind,
        notes: item.notes,
        status: item.status,
        created: item.created,
        lastModified: item.lastModified,
    })), E.mapLeft(io_ts_reporters_1.formatValidationErrors), E.mapLeft(errorList => Err.construct(base_1.IOError.UnableToDecode, {
        errors: JSON.stringify(errorList),
    })));
};
exports.fromUnknownDynamoDbModel = fromUnknownDynamoDbModel;
const arrayFromUnknownDynamoDbModels = (items) => {
    return (0, function_1.pipe)(items, items => {
        if (Array.isArray(items)) {
            return E.right(items);
        }
        return E.left(Err.construct(base_1.IOError.UnableToDecode, {
            error: "expected array but didn't receive one",
            received: JSON.stringify(items, null, 2),
        }));
    }, E.map(Arr.map(exports.fromUnknownDynamoDbModel)), E.map(Arr.partitionMap(function_1.identity)));
};
exports.arrayFromUnknownDynamoDbModels = arrayFromUnknownDynamoDbModels;
const fromUnknown = (item) => {
    return (0, function_1.pipe)(item, exports.Data.decode, E.mapLeft(io_ts_reporters_1.formatValidationErrors), E.mapLeft(errorList => Err.construct(base_1.IOError.UnableToDecode, {
        errors: JSON.stringify(errorList),
    })));
};
exports.fromUnknown = fromUnknown;
const arrayFromUnknown = (items) => {
    return (0, function_1.pipe)(items, items => {
        if (Array.isArray(items)) {
            return E.right(items);
        }
        return E.left(Err.construct(base_1.IOError.UnableToDecode, {
            error: "expected array but didn't receive one",
            received: JSON.stringify(items, null, 2),
        }));
    }, E.map(Arr.map(exports.fromUnknown)), E.map(Arr.partitionMap(function_1.identity)));
};
exports.arrayFromUnknown = arrayFromUnknown;
