import * as H from 'preact/hooks';
import * as E from 'fp-ts/Either';
import * as TE from 'fp-ts/TaskEither';
import * as Err from '@fixtuur/error-context';
import { pipe } from 'fp-ts/function';
import { Model } from '@dora/backpack';
import { serviceUrls } from '../../environment';

const getModelsUrl = new URL(
    `${serviceUrls.toastie.pathname}/models`,
    serviceUrls.toastie,
).toString();

export enum HookError {
    FetchError = 'Unable to fetch resource/collection',
    ResponseJsonError = 'Unable to retrieve json() from fetch response',
    ResponseNotArrayError = 'Object returned by API expected to be an array but is not',
    NoValidItemsError = 'Object returned by API has no items of the expected format',
    NoHttpSuccessCodeError = 'Http call did not receive a success response',
}

// TODO: uncomment created and lastModified when we add these features

export interface ProductRow {
    id: string;
    productId: string;
    visualSpaceStatus: Model.Status | '';
    visualInspirationStatus: Model.Status | '';
    // created: string;
    // lastModified: string;
    articlesReady: number;
    articlesNotReady: number;
    articlesApproved: number;
    articlesRejected: number;
    arModel: boolean;
}
export interface ProductColumn {
    field: keyof ProductRow;
    headerName: string;
}
export const ProductColumns: ProductColumn[] = [
    { field: 'productId', headerName: 'Product ID' },
    { field: 'visualSpaceStatus', headerName: 'VS Status' },
    { field: 'visualInspirationStatus', headerName: 'VI Status' },
    // { field: 'created', headerName: 'Created' },
    // { field: 'lastModified', headerName: 'Last Modified' },
    { field: 'articlesReady', headerName: 'Articles Ready' },
    { field: 'articlesNotReady', headerName: 'Articles Not Ready' },
    { field: 'articlesApproved', headerName: 'Articles Approved' },
    { field: 'articlesRejected', headerName: 'Articles Rejected' },
    { field: 'arModel', headerName: 'AR Model' },
];

export const articleStatusCounter = (status: Model.Status, product: ProductRow) => {
    switch (status) {
        case 'not-ready':
            product.articlesNotReady += 1;
            break;
        case 'ready':
            product.articlesReady += 1;
            break;
        case 'approved':
            product.articlesApproved += 1;
            break;
        case 'rejected':
            product.articlesRejected += 1;
    }
};

/** Construct a list of products from the models fetched */
export function useProductList() {
    const [productRows, setProductRows] = H.useState<ProductRow[]>([]);
    const [refresher, setRefresher] = H.useState(false);

    H.useEffect(() => {
        pipe(
            TE.Do,
            TE.bind('response', () =>
                TE.tryCatch(
                    () => fetch(getModelsUrl),
                    err =>
                        Err.construct(HookError.FetchError, {
                            error: (err as any)?.message,
                            getModelsUrl,
                        }),
                ),
            ),
            TE.chain(original => {
                const { response } = original;
                if (!response.ok) {
                    return TE.left(
                        Err.construct(HookError.NoHttpSuccessCodeError, {
                            responseUrl: response.url,
                            responseStatus: response.status.toString(10),
                            responseStatusText: response.statusText,
                        }),
                    );
                }
                return TE.right(original);
            }),
            TE.bind('modelSummariesUnknown', ({ response }) =>
                TE.tryCatch(
                    () => response.json(),
                    err =>
                        Err.construct(HookError.ResponseJsonError, {
                            error: (err as any)?.message,
                            getModelsUrl,
                            responseUrl: response.url,
                            responseStatus: response.status.toString(10),
                            responseStatusText: response.statusText,
                        }),
                ),
            ),
            TE.bind(
                'modelSummaries',
                ({
                    modelSummariesUnknown,
                }): TE.TaskEither<Err.ErrorContext<HookError>, Model.ModelSummary[]> => {
                    return pipe(
                        modelSummariesUnknown,
                        Model.modelSummariesFromUnknown,
                        // outer either only returns left if item is not array
                        E.mapLeft(Err.swap(HookError.ResponseNotArrayError)),
                        // ignore all items returned that did not decode successfully
                        E.chain(separated => {
                            // errors from models that failed to decode
                            const lefts = separated.left;
                            // models that successfully decoded
                            const rights = separated.right;

                            if (lefts !== null && lefts.length > 0) {
                                // TODO: handle better?
                                console.warn('some model summaries did not decode', { lefts });
                            }

                            if (rights === null) {
                                return E.left(Err.construct(HookError.NoValidItemsError));
                            }

                            return E.right(rights);
                        }),
                        TE.fromEither,
                    );
                },
            ),
            TE.map(({ modelSummaries }) => {
                const products = modelSummaries.reduce((products, m) => {
                    const match = products.find(p => p.productId === m.productId);

                    if (match) {
                        if (m.kind === 'article') {
                            if (match.visualSpaceStatus === '') {
                                match.visualSpaceStatus = m.status;
                                articleStatusCounter(m.status, match);
                            } else {
                                match.visualSpaceStatus = Model.compareStatuses(
                                    match.visualSpaceStatus,
                                    m.status,
                                );

                                articleStatusCounter(m.status, match);
                            }
                        }
                        if (m.kind === 'ar') {
                            if (match.visualInspirationStatus === '') {
                                match.visualInspirationStatus = m.status;
                                match.arModel = true;
                            } else {
                                match.visualInspirationStatus = Model.compareStatuses(
                                    match.visualInspirationStatus,
                                    m.status,
                                );
                                match.arModel = true;
                            }
                        }
                    } else {
                        return products.concat([
                            {
                                id: m.productId,
                                productId: m.productId,
                                visualSpaceStatus: m.kind === 'article' ? m.status : '',
                                visualInspirationStatus: m.kind === 'ar' ? m.status : '',
                                // created: m.created,
                                // lastModified: m.lastModified,
                                arModel: m.kind === 'ar',
                                articlesReady: m.kind === 'article' && m.status === 'ready' ? 1 : 0,
                                articlesNotReady:
                                    m.kind === 'article' && m.status === 'not-ready' ? 1 : 0,
                                articlesApproved:
                                    m.kind === 'article' && m.status === 'approved' ? 1 : 0,
                                articlesRejected:
                                    m.kind === 'article' && m.status === 'rejected' ? 1 : 0,
                            },
                        ]);
                    }
                    return products;
                }, [] as ProductRow[]);

                setProductRows(products);
            }),
            //TODO: do something with errors
            TE.mapLeft(errorContext =>
                console.error('fetch models from toastie failed', { errorContext }),
            ),
        )();
    }, [refresher]);

    const refresh = () => {
        setRefresher(current => !current);
    };

    return { productRows, refresh };
}
