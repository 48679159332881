"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LanguageJSON = void 0;
var LanguageJSON;
(function (LanguageJSON) {
    let LanguageTag;
    (function (LanguageTag) {
        LanguageTag["BritishEnglish"] = "en-GB";
        LanguageTag["French"] = "fr";
        LanguageTag["German"] = "de";
    })(LanguageTag = LanguageJSON.LanguageTag || (LanguageJSON.LanguageTag = {}));
})(LanguageJSON = exports.LanguageJSON || (exports.LanguageJSON = {}));
