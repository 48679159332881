import * as Preact from 'preact';
import * as Hooks from 'preact/hooks';
import List from '@mui/material/List';
import * as CheckCard from '../accordion/checklistPanel/checkCard';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { itemPaddingX } from './dimensions';

const checks = [
    'Check for incorrect seams from UV unwrapping',
    'Check origin forward direction',
    'Check normals look sensible',
    'Check for inside out geometry',
    'Check model size looks correct compared to similar models',
    'Check materials look correct compared to similar models',
    'Check models fit together without overlapping',
    'Check all geometry is present and correct in AR on an iOS device',
];

export const Component = () => {
    const [checkedList, setCheckedList] = Hooks.useState<string[]>([]);

    const handleClick = () => {
        setCheckedList([]);
    };

    return (
        <List>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    height: 'fit-content',
                    marginBottom: '10px',
                }}
            >
                <Button
                    onClick={handleClick}
                    variant="text"
                    size="small"
                    disableRipple
                    sx={{
                        textTransform: 'none',
                        padding: 0,
                        right: `${itemPaddingX}px`,
                        fontSize: '13px',
                    }}
                >
                    uncheck all
                </Button>
            </Box>
            {checks.map(check => (
                <CheckCard.Component
                    check={check}
                    checkedList={checkedList}
                    setCheckedList={setCheckedList}
                />
            ))}
        </List>
    );
};
