"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component = void 0;
var Preact = __importStar(require("preact"));
var icons_1 = require("./icons");
var styles_1 = require("@mui/material/styles");
var material_1 = require("@mui/material");
// TODO: update disabled styling
var StyledButton = (0, styles_1.styled)(material_1.Button)(function (_a) {
    var theme = _a.theme;
    return ({
        height: '36px',
        padding: '6px 16px',
        '&.Mui-disabled': {
            backgroundColor: theme.palette.secondary,
            color: 'inherit',
        },
        color: 'inherit',
        borderRadius: '8px',
        textTransform: 'none',
        '&.MuiButton-outlinedSecondary': {
            border: '1px solid #E0E0E0',
            '&:hover': {
                border: '1px solid #BDBDBD',
            },
        },
        '&.MuiButton-sizeMedium': {
            fontSize: '0.875rem',
            fontWeight: 500,
        },
    });
});
/** Checkout button specifically for use in the basket drawer, within the basket CTA */
var Component = function (p) {
    return (Preact.h(StyledButton, { variant: "contained", size: "medium", onClick: p.onBasketClick, disabled: p.disabled, startIcon: Preact.h(icons_1.CheckoutIcon, null) }, p.total));
};
exports.Component = Component;
