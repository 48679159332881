import AddIcon from '@mui/icons-material/Add';
import { SxProps, Theme } from '@mui/system';
import * as Preact from 'preact';
import * as Hooks from 'preact/hooks';
import * as FileLoadTarget from './fileLoadTarget';
import { Button } from '@dora/components';

export interface Props {
    disabled: boolean;
    onFilesReady: (fs: File[]) => void;
    sx?: SxProps<Theme>;
}

/** Display an `Add Model` button, which opens a file-loading modal when pressed */
export const Component = (p: Props) => {
    const [open, setOpen] = Hooks.useState(false);

    return (
        <>
            <FileLoadTarget.Component
                open={open}
                onClose={() => setOpen(false)}
                onFilesReady={files => {
                    setOpen(false);
                    p.onFilesReady(files);
                }}
                disabled={p.disabled}
            />
            <Button.Component
                variant="contained"
                onClick={() => setOpen(true)}
                sx={{
                    borderRadius: '24px',
                    ...p.sx,
                }}
                disabled={p.disabled}
            >
                <AddIcon /> Add Model
            </Button.Component>
        </>
    );
};
