import * as Preact from 'preact';
import Drawer from '@mui/material/Drawer';
import { appBarHeight } from './appBar/dimensions';

export type Props = {
    children: Preact.ComponentChildren;
};

export const Component = (p: Props) => {
    return (
        <Drawer
            sx={{
                width: '29%',
                '& .MuiDrawer-paper': {
                    width: '29%',
                    boxSizing: 'border-box',
                    top: `${appBarHeight}px`,
                },
            }}
            variant="permanent"
            anchor="right"
        >
            <div>{p.children}</div>
        </Drawer>
    );
};
