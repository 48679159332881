"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDrawerWidth = exports.Platform = exports.fabPadding = exports.appBarHeight = exports.toPixels = void 0;
// Dimensions and padding etc.
var toPixels = function (input) { return "".concat(input, "px"); };
exports.toPixels = toPixels;
exports.appBarHeight = 64;
exports.fabPadding = {
    mobileY: 24,
    mobileX: 20,
    desktopY: 24,
    desktopX: 24,
};
var Platform;
(function (Platform) {
    Platform["DESKTOP"] = "desktop";
    Platform["MOBILE"] = "mobile";
    Platform["TABLET"] = "tablet";
})(Platform = exports.Platform || (exports.Platform = {}));
// Mobile : below 'sm'
var mobilePortraitWidth = '100%';
var mobileLandscapeWidth = '100%';
// Tablet : 'sm' to 'lg'
var tabletPortraitWidth = '460px';
var tabletLandscapeWidth = '460px';
// Desktop : above 'lg'
var desktopWidth = '460px';
function getDrawerWidth(platform, portrait) {
    switch (platform) {
        case Platform.MOBILE:
            return portrait ? mobilePortraitWidth : mobileLandscapeWidth;
        case Platform.TABLET:
            return portrait ? tabletPortraitWidth : tabletLandscapeWidth;
        case Platform.DESKTOP:
            return desktopWidth;
    }
}
exports.getDrawerWidth = getDrawerWidth;
