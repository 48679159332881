"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeatureFlags = exports.BooleanFlagResponse = exports.FeatureFlagServiceState = void 0;
const tslib_1 = require("tslib");
const UserFromJWT_1 = require("../UserFromJWT");
var FeatureFlagServiceState;
(function (FeatureFlagServiceState) {
    FeatureFlagServiceState["Uninitialised"] = "UNINITIALISED";
    FeatureFlagServiceState["Initialising"] = "INITIALISING";
    FeatureFlagServiceState["Ready"] = "READY";
    FeatureFlagServiceState["TimedOut"] = "TIMEDOUT";
})(FeatureFlagServiceState = exports.FeatureFlagServiceState || (exports.FeatureFlagServiceState = {}));
/**
 * A boolean flag response can technically be in three states - default is used in the case that a
 * flag is not / no longer defined OR the service is unobtainable.
 */
var BooleanFlagResponse;
(function (BooleanFlagResponse) {
    BooleanFlagResponse["On"] = "on";
    BooleanFlagResponse["Off"] = "off";
    BooleanFlagResponse["Default"] = "DEFAULT";
})(BooleanFlagResponse = exports.BooleanFlagResponse || (exports.BooleanFlagResponse = {}));
/**
 * Service for pulling down features based on current user / environment key.
 * It should be shutdown gracefully if possible!
 */
class FeatureFlags {
    constructor(_authNetwork, _featureFlagService) {
        this._authNetwork = _authNetwork;
        this._featureFlagService = _featureFlagService;
        const currentJWT = _authNetwork.getJWT();
        if (!currentJWT) {
            this._featureFlagService.user = '00000000000000000000000000000000';
        }
        else {
            this._featureFlagService.user = (0, UserFromJWT_1.getUserFromJWT)(currentJWT);
        }
        // If IJWTNetwork token changes, decode it and fetch the new user sub.
        this._authNetwork.onJWTChange(token => {
            this._featureFlagService.user = (0, UserFromJWT_1.getUserFromJWT)(token);
            this._featureFlagService.shutdown().then(() => {
                this._featureFlagService.initialise();
            });
        });
    }
    /**
     * Retrieve the current detected user subject from the jwt.
     */
    get user() {
        return this._featureFlagService.user;
    }
    /**
     * Retrieve the current state of the feature flag service
     */
    get state() {
        return this._featureFlagService.state;
    }
    /**
     * Shutdown any feature flag service gracefully.
     */
    shutdown() {
        return this._featureFlagService.shutdown();
    }
    /**
     * Retrieve a list of all available flags for the current user.
     */
    allFlags() {
        return this.onInit()
            .then(() => {
            return this._featureFlagService.flags();
        })
            .catch((e) => {
            console.warn(e.message);
            return [];
        });
    }
    /**
     * Gets configurations.
     * Configurations are custom JSON (or key-values) defined per flag value.
     * Typically used to modify feature behaviour on the fly.
     */
    getFlagValueConfigurations(flagName, value) {
        return this.onInit().then(() => this._featureFlagService.getFlagValueConfigurations(flagName, value));
    }
    /**
     * Retrieve the boolean value of a feature flag.
     * This relies on the sdk itself being initialised and the flag cached locally. As such, the enabled / disabled
     * operations should be considered asynchronous.
     * @param flagName the flag to look up.
     * @param defaultValue (default to false) will be returned if the flag does not exist or the service cannot be retrieved
     */
    getBooleanFlagValue(flagName, defaultValue = false) {
        return this.onInit()
            .then(() => {
            if (!this._featureFlagService.isFlagBoolean(flagName)) {
                console.warn(`Expected a boolean flag, but flag is not boolean: ${flagName}`);
                return Promise.resolve(defaultValue);
            }
            return Promise.resolve(this.resolveBooleanFlag(flagName));
        })
            .catch((e) => {
            console.warn(e.message);
            return Promise.resolve(defaultValue);
        });
    }
    /** Returns true if all flags are enabled. */
    areBooleanFlagsEnabled(flagNames) {
        return this.onInit()
            .then(() => {
            const flagResults = this._featureFlagService.getMultipleBooleanFlags(flagNames);
            return Object.keys(flagResults).every(flagName => !!flagResults[flagName] &&
                flagResults[flagName] === BooleanFlagResponse.On);
        })
            .catch((e) => {
            console.warn(e.message);
            return false;
        });
    }
    /**
     * Retrieve the raw value of a feature flag.
     * In the case of a boolean flag this may return 'on', 'off' or provided default.
     * In the case of a multi variant flag, this will return the response.
     * @param flagName the flag to look up.
     * @param defaultValue value which will be returned if the flag does not exist or the service cannot be retrieved
     */
    getFlagValue(flagName, defaultValue) {
        return this.onInit()
            .then(() => {
            return this._featureFlagService.getMultiVariantFlag(flagName);
        })
            .catch((e) => {
            console.warn(e.message);
            return defaultValue;
        });
    }
    /**
     * Track a metric with respect to the current state of features.
     * If a metric is tracked here, it should be specifically a success metric to gauge the usefulness of a WIP feature.
     * @param metricName the metric to track.
     * @param value optional number to attribute to the metric (e.g. seconds taken to load x).
     * @param properties optional string / number / boolean dictionary used to assign to metrics collected.
     */
    trackMetric(metricName, value, properties) {
        this.onInit()
            .then(() => {
            return this._featureFlagService.trackEvent(metricName, value, properties);
        })
            .catch((e) => {
            console.warn(e.message);
        });
    }
    setUserAttributes(attributes) {
        this._featureFlagService.userAttributes = attributes;
    }
    /**
     * Private function for resolving Boolean feature flags
     */
    resolveBooleanFlag(flagName) {
        const flag = this._featureFlagService.getBooleanFlagValue(flagName);
        switch (flag) {
            case BooleanFlagResponse.On:
                return true;
            case BooleanFlagResponse.Off:
                return false;
            case BooleanFlagResponse.Default:
                return false;
        }
    }
    /**
     * Private function for resolving Non-boolean feature flags.
     */
    resolveFlag(flagName, callback) {
        const flag = this._featureFlagService.getMultiVariantFlag(flagName);
        callback(flag);
    }
    /**
     * Create a Listener for when the SDK Updates.
     */
    createUpdateListener(listener) {
        return this.onInit()
            .then(() => this._featureFlagService.addUpdateListener(listener))
            .catch((e) => {
            console.warn(e.message);
        });
    }
    /**
     * Destroy an Update Listener
     */
    destroyUpdateListener(listener) {
        return this.onInit()
            .then(() => this._featureFlagService.destroyUpdateListener(listener))
            .catch((e) => {
            console.warn(e.message);
        });
    }
    /**
     * State management
     * We can only attach one SDK_READY event at any given time so this function handles that by tracking
     * three potential states (UNINITIALISED, INITIALISING and READY) and resolving via one of three ways:
     * * Uninitialised:     Set state to Initialising, subscribe and set state to Ready when complete or set state
     *                      to TimedOut if failed. Retries immediately to trigger Initialising behaviour.
     * * Initialising:      Retry every 0.5 seconds
     * * Ready:             Resolve immediately
     * * TimedOut:          Reject
     */
    onInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            switch (this._featureFlagService.state) {
                // Subscribe to events to change state and trigger retry.
                case FeatureFlagServiceState.Uninitialised: {
                    this._featureFlagService.initialise();
                    return this.onInit();
                }
                // Retry every 0.5 seconds
                case FeatureFlagServiceState.Initialising: {
                    const delay = t => new Promise(resolve => setTimeout(resolve, t));
                    return delay(500).then(() => this.onInit());
                }
                // Resolve immediately
                case FeatureFlagServiceState.Ready: {
                    return Promise.resolve();
                }
                // Reject immediately
                case FeatureFlagServiceState.TimedOut: {
                    return Promise.reject(new Error('Failed to initialise Feature Flag service, timed out!'));
                }
            }
        });
    }
}
exports.FeatureFlags = FeatureFlags;
